import contrib from "lodash-contrib";
import {DATE_FORMAT_TYPE} from "src/constants/app";

const _ = require('lodash')

export const DIRECT_ACCESSIBLE_STAGES = ["beta", "prod"]
export const DOWNLOAD_TYPES = {
    TEXT: "Text",
    EXCEL: "Excel",
    ZIP: "ZIP",
    BATCH: "BATCH",
    CSV: "CSV"
}
export const VIEW_ROW_LIMIT = 1000;
export const renameKeys = {
    reportType: "Report Type",
    beginDate: "Begin Date",
    endDate: "End Date",
    vendorCode: "Vendor Code",
    marketPlaceName: "MarketPlace",
    marketPlaceId: "marketplaceId",
    isValid: "Is Valid",
    reportDate: "Created On",
    reportId: "Report Id",
    sumOfCostsCurrencyCode: "Currency",
    sumOfCosts: "Amount",
    metadata: "Metadata",
    isS3Report: "View",
    "Acc. Amount": "Accumulated Amount",
    formatTypes: "Formats"
}
export const hiddenItems = ["Report Id", "Download Links", "Metadata", "Created On"]
export const extraItems = ["marketplaceId", renameKeys["Acc. Amount"], renameKeys.formatTypes]
export const toOmit = ['description',
    'lastUpdatedDate', 'reportLocationType', 'sumOfCostsWithAdjustmentAmount', 'sumOfCostsWithAdjustmentAmountCurrencyCode']
export const dateColumns = ['Begin Date', 'End Date', 'Created On']
export const amountColumns = ['Amount']
export const REPORT_MODAL_TYPES = {
    VIEW_REPORT: 'VIEW_REPORT',
    ACCUMULATED_DISBURSEMENT: 'ACCUMULATED'
}
export const defaultFormatTypes = ["csv", "excel", "text"]
export const getGlForReportType = (claimsData, reports, type = null) => {
    if (reports == null && _.isEmpty(type)) return []
    const reportTypes = type != null ? [type] : _.uniq(reports.map(r => r[renameKeys.reportType]))
    const matchedGls = []
    claimsData.reportTypes.forEach(claims =>
        reportTypes.forEach(r => {
            if (claims.reportTypes.includes(r) && !matchedGls.includes(claims.id))
                matchedGls.push(claims.id)
        })
    )
    return matchedGls
}
export const canTriggerLiveReport = (reports) => {
    if (_.isEmpty(reports)) return false
    const reportTypes = _.uniq(reports.map(r => r[renameKeys.reportType]))
    return ALLOWED_LIVE_REPORT_TYPES.some(allowed => reportTypes.includes(allowed))
}
export const getMarketPlaceFromName = (claimsData, market) => {
    if (!market) return []
    const index = claimsData.marketNames.findIndex(a => a == market)
    if (index != -1) return [claimsData.marketIds[index]]
    return []
}

export function convertUnixtimestampToDateString(unixtimestamp, dateFormat) {
    switch (dateFormat) {
        case DATE_FORMAT_TYPE.DATE_TIME:
            return (new Date(unixtimestamp * 1000))
                .toISOString()
                .replace('T', ' ')
                .split('.')[0];
        case DATE_FORMAT_TYPE.DATE:
        default:
            return (new Date(unixtimestamp * 1000))
                .toISOString()
                .split('T')[0];
    }
}


export const formatReports = (reports, stageConfig) => {
    if (!reports) return []
    if (reports) {
        return reports.map(r => {
            r = _.omit(r, toOmit)
            r = contrib.renameKeys(r, renameKeys)
            r[renameKeys.isValid] = r[renameKeys.isValid] === true ? 'Yes' : 'No'
            _.values(renameKeys).forEach(v => {
                if (!_.has(r, v)) r[v] = ""
            })
            // The dates are displayed as UTC time to show consistent time across all regions.
            r['Begin Date'] = convertUnixtimestampToDateString(
                _.toInteger(r['Begin Date']),
                DATE_FORMAT_TYPE.DATE
            )
            r['End Date'] = convertUnixtimestampToDateString(
                _.toInteger(r['End Date']),
                DATE_FORMAT_TYPE.DATE
            )
            r['Created On'] = convertUnixtimestampToDateString(
                _.toInteger(r['Created On']),
                DATE_FORMAT_TYPE.DATE_TIME
            )
            r[renameKeys.isS3Report] = r[renameKeys.isS3Report] === true ? '' : 'View'
            r.Metadata = JSON.stringify(r.Metadata)
            if (!r.Currency)
                r.Currency = ''
            if (!r.Amount)
                r.Amount = ''
            else
                r['Amount'] = r['Amount'].toFixed(2)
            if (!r[renameKeys.formatTypes])
                r[renameKeys.formatTypes] = []
            else
                r[renameKeys.formatTypes] = r[renameKeys.formatTypes].map(d => d.toLowerCase())

            return {
                "Report Id": r["Report Id"],
                "Report Type": r["Report Type"],
                "Begin Date": r["Begin Date"],
                "End Date": r["End Date"],
                "Vendor Code": r["Vendor Code"],
                "MarketPlace": r["MarketPlace"],
                "Is Valid": r["Is Valid"],
                "Created On": r["Created On"],
                "Amount": r["Amount"],
                "Currency": r["Currency"],
                "Metadata": r["Metadata"],
                ...r,
                [renameKeys['Acc. Amount']]: ""
            }
        })
    }
}


export const LIVE_SUCCESS_STATUS = ["REPORT_REQUEST_PROCESSED"]
export const PARTIAL_SUCCESS_STATUS = ["REPORT_UPLOADED_TO_S3", "REPORT_PUBLISHED_TO_R2MS"]
export const ALLOWED_LIVE_BUSINESS = ['Appstore', 'DSV', 'EPubs', 'ManagedEBooks']
export const ALLOWED_LIVE_REPORT_TYPES = [
    "ePubsPaymentPlusTaxReport",
    "ePubsTaxOnlyReport",
    "eBooksPaymentPlusTaxReport",
    "eBooksRentalsPaymentPlusTaxReport",
    "eBooksISORPaymentPlusTaxReport",
    "TSORDiscountPoolReport",
    "SoftwareASINReport",
    "SoftwareASINSummaryReport",
    "VideoGamesASINReport",
    "VideoGamesASINSummaryReport",
    "AppstoreAgencyTax",
    "ePubsSingleIssueASINReport",
    "ePubsSubscriptionASINReport",
    "IDVePubsSingleIssueASINReport",
    "IDVePubsSubscriptionASINReport"
]