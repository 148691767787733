export interface Claims {
    markets: Record<string, string> | {};
    marketIds: Record<string, number> | {};
    reportTypes: [Record<string, [string]>] | [];
    shadowTypes: [any] | [];
    glReportMap: Record<string, any>;
    isInternal: Boolean;
    hasUpdatePermission: Boolean;
    errorMessage: any;
}


export const UPDATE_CLAIMS: string = "@@CLAIMS/UPDATE_CLAIMS";

interface UPDATE_CLAIMS_ACTION {
    type: typeof UPDATE_CLAIMS;
    payload: Claims;
}

export type ClaimsActionTypes = UPDATE_CLAIMS_ACTION;