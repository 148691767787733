import Modal from "react-modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, {useCallback, useEffect, useRef} from "react";
import useState from 'react-usestateref'
import Loader from "@amzn/meridian/loader";
import {refreshMidway} from "src/authentication/midwayTokenRetriever";
import {useSelector} from "react-redux";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import {getCurrentStageConfig} from "src/constants/stage";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Input from "@amzn/meridian/input";
import './styles/styles.css'
import {getVendorGroupByCustomerId, getVendorGroupByVendorCode} from "src/apis/viss";
import {DataViewer} from "src/components/vendorIdentity/DataViewer";
import Select, {SelectOption} from "@amzn/meridian/select";
import Link from "@amzn/meridian/link";
import {ALLOWED_BUIDS, BUCKET_STATUSES} from "src/constants/app";
import DatePicker from "@amzn/meridian/date-picker";

const _ = require('lodash');


export const InputBar = (props) => {

    const {
        claimsData, marketPlace, setMarketPlace, marketPlaceRef,
        productGL, setProductGL, productGLRef,
        bucketStatus, setBucketStatus, bucketStatusRef,
        buid, setBuid, buidRef, loadBuckets,
        loadShadow, setLoadShadow, startDate, setStartDate, downloadState
    } = props;

    const selectAllMarketPlaces = (isSet = true) => {
        setMarketPlace(isSet ? Object.keys(claimsData.markets) : [])
    }
    const isAllMarketPlaceSelected = () => Object.keys(claimsData.markets).length === marketPlaceRef.current.length

    const selectAllBucketStatus = (isSet = true) => {
        setBucketStatus(isSet ? BUCKET_STATUSES : [])
    }
    const isALlBucketStatusSelected = () => BUCKET_STATUSES.length === bucketStatusRef.current.length

    const selectAllBUIDS = (isSet = true) => {
        setBuid(isSet ? ALLOWED_BUIDS : [])
    }
    const isALlBUIDSelected = () => ALLOWED_BUIDS.length === buidRef.current.length

    return (

        <Row width="100%" widths={["90%", "10%"]} alignmentVertical="bottom">
            <Row width="100%" alignmentHorizontal="left" alignmentVertical="bottom">
                {/*marketplace*/}
                <Select
                    value={marketPlace}
                    placeholder="Select Marketplace"
                    width="10%"
                    helperText="Marketplace"
                    onChange={setMarketPlace}
                    popoverMaxHeight={350}
                    valueLabel={values => {
                        if (isAllMarketPlaceSelected()) {
                            return `All Marketplaces`
                        } else if (values.length === 1) {
                            return `Selected ${values[0].label}`
                        } else if (values.length > 1) {
                            return `${values.length} Selected`
                        } else {
                            return ""
                        }
                    }}>
                    <SelectOption label="" value="links" disabled={true}>
                        {
                            () => {
                                return (
                                    <Row alignmentHorizontal="justify">
                                        <Link onClick={() => selectAllMarketPlaces(true)}
                                              disabled={isAllMarketPlaceSelected()}
                                              type="secondary">Select All</Link>
                                        <Link onClick={() => selectAllMarketPlaces(false)}
                                              type="secondary">Clear</Link>
                                    </Row>
                                )
                            }
                        }
                    </SelectOption>
                    {Object.entries(claimsData.markets).map(([symbol, name]) => (
                        <SelectOption
                            key={symbol}
                            value={symbol}
                            label={name}
                        />
                    ))}
                </Select>

                {/*Product Gl*/}
                <Select
                    value={productGL}
                    placeholder="Select GL"
                    width="10%"
                    helperText="Product GL"
                    onChange={setProductGL}
                    popoverMaxHeight={350}>
                    <SelectOption label="" value="links" disabled={true}>
                        {
                            () => {
                                return (
                                    <Row alignmentHorizontal="justify">

                                        <Link onClick={() => setProductGL("")}
                                              type="secondary">Clear</Link>
                                    </Row>
                                )
                            }
                        }
                    </SelectOption>
                    {Object.keys(claimsData.glReportMap).map(gl => (
                        <SelectOption
                            key={gl}
                            value={gl}
                            label={gl}
                        />
                    ))}
                </Select>

                {/*Load Shadow Buckets*/}
                <Select
                    value={loadShadow}
                    placeholder="Show Shadow?"
                    width="10%"
                    helperText="Shadow Buckets"
                    onChange={setLoadShadow}
                    popoverMaxHeight={350}>
                    {["Yes", "No"].map(val => (
                        <SelectOption
                            key={val}
                            value={val}
                            label={val}
                        />
                    ))}
                </Select>


                {/*Bucket Status*/}
                <Select
                    value={bucketStatus}
                    placeholder="Select BucketStatus"
                    width="20%"
                    helperText="BucketStatus"
                    onChange={setBucketStatus}
                    popoverMaxHeight={350}
                    valueLabel={values => {
                        if (isALlBucketStatusSelected()) {
                            return `All Status`
                        } else if (values.length === 1) {
                            return `Selected ${values[0].label}`
                        } else if (values.length > 1) {
                            return `${values.length} Selected`
                        } else {
                            return ""
                        }
                    }}>
                    <SelectOption label="" value="links" disabled={true}>
                        {
                            () => {
                                return (
                                    <Row alignmentHorizontal="justify">
                                        <Link onClick={() => selectAllBucketStatus(true)}
                                              disabled={isALlBucketStatusSelected()}
                                              type="secondary">Select All</Link>
                                        <Link onClick={() => selectAllBucketStatus(false)}
                                              type="secondary">Clear</Link>
                                    </Row>
                                )
                            }
                        }
                    </SelectOption>
                    {BUCKET_STATUSES.map(status => (
                        <SelectOption
                            key={status}
                            value={status}
                            label={status}
                        />
                    ))}
                </Select>

                {/*start date*/}
                <DatePicker width={"10%"} helperText="Start Date" value={startDate} onChange={setStartDate}
                            monthsInView={1} placeholder="Start Date"/>

                {/*businessUnitId*/}
                <Select
                    value={buid}
                    placeholder="Select BUID"
                    width="10%"
                    helperText="Business Id"
                    onChange={setBuid}
                    popoverMaxHeight={350}
                    valueLabel={values => {
                        if (isALlBUIDSelected()) {
                            return `All BUID's`
                        } else if (values.length === 1) {
                            return `Selected ${values[0].label}`
                        } else if (values.length > 1) {
                            return `${values.length} Selected`
                        } else {
                            return ""
                        }
                    }}>
                    <SelectOption label="" value="links" disabled={true}>
                        {
                            () => {
                                return (
                                    <Row alignmentHorizontal="justify">
                                        <Link onClick={() => selectAllBUIDS(true)}
                                              disabled={isALlBUIDSelected()}
                                              type="secondary">Select All</Link>
                                        <Link onClick={() => selectAllBUIDS(false)}
                                              type="secondary">Clear</Link>
                                    </Row>
                                )
                            }
                        }
                    </SelectOption>
                    {ALLOWED_BUIDS.map(b => (
                        <SelectOption
                            key={b}
                            value={b}
                            label={b}
                        />
                    ))}
                </Select>
            </Row>
            <Row width="100%" alignmentHorizontal="right" alignmentVertical="bottom">
                {/*Button*/}
                <Button onClick={loadBuckets} disabled={downloadState} type="primary">Fetch!</Button>
            </Row>
        </Row>


    )
}