import { ADD_API_ERROR, REMOVE_API_ERROR } from "./actions";
import { AppState, AppActionTypes } from "./types";
import { Reducer } from "redux";
const initialState: AppState = {
    isInitializing: true,
    errored: false,
    apiErrors: []
};

export const AppReducer: Reducer<AppState> = (
    state: AppState = initialState,
    action
) => {
    switch ((action as AppActionTypes).type) {
        case "@@APP/UPDATE_APP_STATE":
            return { ...state, ...action.payload };
        case ADD_API_ERROR:
            return {
                ...state,
                apiErrors: [
                    ...state.apiErrors,
                    {...action.payload, id: `${state.apiErrors.length}`, timeout: 4000}
                ]
            };
        case REMOVE_API_ERROR:
            return { ...state, apiErrors: state.apiErrors.filter(error => error.id != action.payload) };
        default:
            return state;
    }
};