import React, {useCallback} from "react";
import initialMetricsPublisher from "../../metrics";
import Row from "@amzn/meridian/row";
import {ReconcileDashboard} from "src/components/monthendReconcile/ReconcileDashboard";


export class ReconcileRoot extends React.Component {

    componentDidMount() {
        const actionMetricsPublisher =
            initialMetricsPublisher.newChildActionPublisherForMethod("PageLoad");
        actionMetricsPublisher.publishCounterMonitor('reconcile-page-load', 1);
    }

    render() {
        return (
            <Row width="100%" widths={["grid-12"]}>
                <div style={{
                    overflowX: "auto",
                    maxWidth: "100%",
                    padding: "5px",
                }}>
                    <ReconcileDashboard/>
                </div>
            </Row>
        )
    }
}

export default ReconcileRoot;