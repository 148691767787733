export const getCustomModalStyle = (isDarkMode = false, height = "40%", width = "60%") => {
    return {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            height: height,
            width: width,
            overlfow: 'scroll',
            "background-color": isDarkMode ? "#252829" : "white"
        },
        overlay: {
            zIndex: 10
        }
    };
}
