import React, {useCallback, useContext, useEffect, useRef} from "react";
import useState from 'react-usestateref'
import Pagination from "@amzn/meridian/pagination"
import Column from "@amzn/meridian/column"
import Alert from "@amzn/meridian/alert"
import Table, {
    TableRow,
    TableCell,
    TableActionBar,
    TableActionBarOverlay,
} from "@amzn/meridian/table"
import Toggle from "@amzn/meridian/toggle"
import Row from "@amzn/meridian/row"
import Checkbox from "@amzn/meridian/checkbox"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import {getUsername, refreshMidway} from "src/authentication/midwayTokenRetriever";
import {downloadBatchReports, downloadReport, fetchReports, getAccumulatingDisbursement} from "src/apis";
import Text from "@amzn/meridian/text"
import minusIconTokens from "@amzn/meridian-tokens/base/icon/minus"
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large"
import viewTokens from "@amzn/meridian-tokens/base/icon/view"
import {format, parse} from 'date-fns'

const _ = require('lodash');
const contrib = require('lodash-contrib');
const downloadJs = require('downloadjs')
import Modal from "react-modal";
import './styles/styles.css'
import {
    PAGE_LOCATIONS,
    REPORTS_PAGE_SIZE,
    REPORTS_MULTI_SELECT_LIMIT,
    REPORTS_ROW_COUNT,
    DELAY, ALLOWED_TO_SELECT_IN_ALL_PAGES
} from "src/constants/app";
import {
    amountColumns,
    dateColumns,
    defaultFormatTypes,
    DIRECT_ACCESSIBLE_STAGES,
    DOWNLOAD_TYPES,
    extraItems,
    getGlForReportType,
    getMarketPlaceFromName,
    hiddenItems,
    renameKeys,
    REPORT_MODAL_TYPES,
    VIEW_ROW_LIMIT
} from "src/components/reports/ReportConstants";
import {connect} from "react-redux";
import useWindowDimensions from "src/components/common/Dimension";
import {DisbursementTable} from "src/components/liveReport/DisbursementTable";
import Menu, {MenuItem} from "@amzn/meridian/menu";
import ButtonGroup, {ButtonOption} from "@amzn/meridian/button-group";
import {ModalViewComponent} from "src/components/reports/ViewModal";
import {readRemoteFile} from 'react-papaparse'
import Link from "@amzn/meridian/link";
import {ConfirmStatusChange} from "src/components/reports/ConfirmStatusChangeModal";
import {getExportFileName, makeCsv} from "src/components/reports/Utils";
import {GeneralConfirmDialog} from "src/components/reports/GeneralConfirmDialog";
import Toaster from "@amzn/meridian/toaster";
import {ThemeContext} from "src/context/ThemeContext";
import {addServiceError} from "src/reducers/app/actions";


const mapDispatch = {
    dispatchError: (value) => addServiceError(value)
};

const connector = connect(null, mapDispatch);

export const TableSectionComponent = (props) => {
    if (process.env.NODE_ENV !== 'test')
        Modal.setAppElement('#root');
    const {
        hasGlAccess, loaderState, makingCall,
        dispatchError, tableData, tableDataRef,
        selected, setSelected, selectedRef,
        currentPage, currentPageRef, setCurrentPage,
        headerCheckBoxIcon, setHeaderCheckBoxIcon,
        allCheckedInCurrent, setAllCheckedInCurrent,
        onLoadMoreHandler, loadMoreState,
        hasMore, hasMoreRef,
        marketPlaceRef, setTableData,
        claimsData, stageConfig,
        disbursementData, disbursementDataRef,
        setOpenFeedback, canTriggerLive
    } = props
    let {reports} = tableData || {}

    const [showDetails, setShowDetails] = useState(false);
    const [downloadState, setDownloadState, downloadStateRef] = useState(false)
    const themeContext = useContext(ThemeContext);

    //const pagination selection
    const {height, width} = useWindowDimensions();
    const [paginationValue, setPaginationValue, paginationValueRef] = useState(_.toInteger(height / REPORTS_ROW_COUNT) - (width > 1000 ? 0 : 1))
    const numberOfPages = Math.ceil(reports ? (reports.length / paginationValue) : 0)


    //sorting
    const [sortColumn, setSortColumn] = useState("Begin Date")
    const [sortDirection, setSortDirection] = useState("descending")
    const onSort = useCallback(({sortColumn, sortDirection}) => {
        setSortDirection(sortDirection)
        setSortColumn(sortColumn)
    }, [])
    reports && reports.sort((a, b) => {
        if (dateColumns.includes(sortColumn)) {
            const parsedValueA = sortColumn === renameKeys.reportDate ? a[sortColumn].split(" ")[0] : a[sortColumn]
            const parsedValueB = sortColumn === renameKeys.reportDate ? b[sortColumn].split(" ")[0] : b[sortColumn]
            if (parse(parsedValueA, stageConfig.DATE.format, new Date()) < parse(parsedValueB, stageConfig.DATE.format, new Date())) {
                return sortDirection === "ascending" ? -1 : 1
            }
            if (parse(parsedValueA, stageConfig.DATE.format, new Date()) > parse(parsedValueB, stageConfig.DATE.format, new Date())) {
                return sortDirection === "ascending" ? 1 : -1
            }
        } else if (amountColumns.includes(sortColumn)) {
            if (_.toNumber(a[sortColumn]) < _.toNumber(b[sortColumn])) {
                return sortDirection === "ascending" ? -1 : 1
            }
            if (_.toNumber(a[sortColumn]) > _.toNumber(b[sortColumn])) {
                return sortDirection === "ascending" ? 1 : -1
            }
        } else {
            if (a[sortColumn] < b[sortColumn]) {
                return sortDirection === "ascending" ? -1 : 1
            }
            if (a[sortColumn] > b[sortColumn]) {
                return sortDirection === "ascending" ? 1 : -1
            }
        }
        return 0
    })

    const shouldReportBeAllowedToDownloadInOtherFormats = (report) => {
        /*temporarily stopping csv/txt/excel downloads for cage reports [https://t.corp.amazon.com/D44444444/communication]*/
        if (!_.isEmpty(report[renameKeys.metadata]) && report[renameKeys.metadata].includes('CaspianFinalizerService') &&
            parse(report[renameKeys.reportDate].split(" ")[0], stageConfig.DATE.format, new Date()) < new Date(2022, 5, 1)
        )
            return false;
        return !(report[renameKeys.formatTypes].length === 1 && report[renameKeys.formatTypes].includes(DOWNLOAD_TYPES.ZIP.toLowerCase()))
    }
    const checkBoxChangeHandler = () => {
        const startIndex = (currentPageRef.current - 1) * paginationValue
        const endIndex = startIndex + paginationValue
        if(reports){
            const currentPageReports = reports.slice(startIndex, endIndex)
            const currentPageReportIds = currentPageReports.filter(r => shouldReportBeAllowedToDownloadInOtherFormats(r)).map(r => r[renameKeys.reportId])
            const isSelectedInCurrentPage = currentPageReportIds.some(id => _.keys(selectedRef.current).includes(id) && selectedRef.current[id] === true)
            const allCheckedInCurrentPage = currentPageReportIds.every(id => _.keys(selectedRef.current).includes(id) && selectedRef.current[id] === true)
            const notSelectedInCurrentPagesIds = currentPageReportIds.filter(id => selectedRef.current[id] != true)
            setAllCheckedInCurrent(isSelectedInCurrentPage)
            setHeaderCheckBoxIcon(!allCheckedInCurrentPage && isSelectedInCurrentPage ? "minusIconTokens" : undefined)

            //modify allowed types
            const selectedReportIds = _.keys(selectedRef.current).filter(s => selectedRef.current[s] === true)
            if (_.isEmpty(selectedReportIds))
                setAllowedFormatTypes(defaultFormatTypes)
            else {
                const allowedTypes = []
                reports.forEach(r => {
                    if (selectedReportIds.includes(r[renameKeys.reportId]))
                        allowedTypes.push(r[renameKeys.formatTypes])
                })
                const commonTypes = allowedTypes.reduce((p, c) => p.filter(e => c.includes(e)), _.isEmpty(allowedTypes) ? defaultFormatTypes : allowedTypes[0])
                setAllowedFormatTypes(commonTypes)
            }
            return [allCheckedInCurrentPage, isSelectedInCurrentPage, currentPageReportIds, notSelectedInCurrentPagesIds] 
        }
    }

    //pagination
    const firstVisibleIndex = (currentPage - 1) * paginationValue
    const lastVisibleIndex = firstVisibleIndex + paginationValue
    const paginationHandler = useCallback((newPageNumber) => {
        setCurrentPage(newPageNumber)
        checkBoxChangeHandler()
    }, [checkBoxChangeHandler])


    //checkbox selection
    const count = reports ? reports.filter(r =>
        !(r[renameKeys.formatTypes].length === 1 && r[renameKeys.formatTypes].includes(DOWNLOAD_TYPES.ZIP.toLowerCase()))
    ).length : 0
    const checkedCount = Object.values(selected || {}).filter(Boolean).length
    const allSelected = count === checkedCount
    //invoked when user clicks on checkbox on each row
    const onChangeRow = useCallback(
        id => value => {
            if (value === true && checkedCount >= REPORTS_MULTI_SELECT_LIMIT()) {
                dispatchError(`A maximum of ${REPORTS_MULTI_SELECT_LIMIT()} reports can be selected at once for batch download.`);
            } else {
                setSelected({...selected, [id]: value})
            }
            checkBoxChangeHandler()
        },
        [selected]
    )
    //invoked when user clicks on checkbox on action toolbar
    const onChangeHeader = useCallback(() => {
        const [allCheckedInCurrentPage, isSelectedInCurrentPage, currentPageReportIds, notSelectedInCurrentPagesIds] = checkBoxChangeHandler()
        if (ALLOWED_TO_SELECT_IN_ALL_PAGES()) {
            const nextSelected = {}
            const filteredIds = reports.filter(r =>
                !(r[renameKeys.formatTypes].length === 1 && r[renameKeys.formatTypes].includes(DOWNLOAD_TYPES.ZIP.toLowerCase()))
            ).map(r => r[renameKeys.reportId])
            filteredIds.forEach(d => {
                nextSelected[d] = !!!allCheckedInCurrentPage
            })
            setSelected({...selectedRef.current, ...nextSelected})
            checkBoxChangeHandler()
            return
        }
        if (!!!allCheckedInCurrentPage === true && (checkedCount + notSelectedInCurrentPagesIds.length) > REPORTS_MULTI_SELECT_LIMIT()) {
            dispatchError(`A maximum of ${REPORTS_MULTI_SELECT_LIMIT()} reports can be selected at once for batch download.`);
        } else {
            const nextSelected = {}
            currentPageReportIds.forEach(d => {
                nextSelected[d] = !!!allCheckedInCurrentPage
            })
            setSelected({...selectedRef.current, ...nextSelected})
            checkBoxChangeHandler()
        }
    }, [allSelected, selected])


    //download handlers
    const getFileName = (headerValues, fallBackName, fileType) => {
        const index = _.isEmpty(headerValues) ? -1 : headerValues.indexOf("filename=\"")
        if (index !== -1)
            return headerValues.substring(index + 10, headerValues.length - 1)
        return `${fallBackName}.${fileType.toLowerCase()}`
    }
    const saveFile = (report, data, downloadType) => {
        //if the url can be accessed directly use that
        if (DIRECT_ACCESSIBLE_STAGES.includes(stageConfig.STAGE)) {
            window.open(data.url, "_blank");
            return;
        }

        if (downloadType === DOWNLOAD_TYPES.TEXT)
            downloadJs(`data:text/plain;base64,${data['fileData']}`, getFileName(data.headerValues, `${report['Vendor Code']}-${report['Report Type']}`, "txt"), "text/plain")
        else if (downloadType == DOWNLOAD_TYPES.EXCEL)
            downloadJs(`data:application/vnd.ms-excel;base64,${data['fileData']}`, getFileName(data.headerValues, `${report['Vendor Code']}-${report['Report Type']}`, "xls"), "application/vnd.ms-excel")
        else if (downloadType === DOWNLOAD_TYPES.ZIP)
            downloadJs(`data:application/zip;base64,${data['fileData']}`, getFileName(data.headerValues, `${report['Vendor Code']}-${report['Report Type']}`, "zip"), "application/zip")
        else if (downloadType === DOWNLOAD_TYPES.BATCH)
            downloadJs(`data:application/zip;base64,${data['fileData']}`, getFileName(data.headerValues, `${new Date().valueOf()}`, "zip"), "application/zip")
        else
            dispatchError("Invalid File Format, the valid file formats are .txt, .xls, .zip. Please try Again!")
    }
    const makeDownloadAPICall = async (report, downloadType, useXlsx = false, fromViewHandler = false) => {
        const credentials = await refreshMidway();
        const user = await getUsername(credentials);
        const inputParams = {
            "reportId": report['Report Id'],
            "requesterId": (user && user.username) || "",
            "reportFormatType": downloadType === "ZIP" ? "ZIP" : downloadType,
            "reportHeaderType": "Header",
            "reportCompressionType": "NoCompression",
            "allowInvalidReports": true,
            "getData": false,
            "useXlsx": useXlsx
        }
        const data = await downloadReport(credentials.token, inputParams)
        if (data && data.errorMessage) {
            dispatchError(data)
            return;
        }
        if (fromViewHandler === true) {
            return data
        }
        saveFile(report, data, downloadType)
    }
    const makeBatchDownloadAPICall = async (reports, downloadType = DOWNLOAD_TYPES.TEXT, useXlsx = false) => {
        const credentials = await refreshMidway();
        const user = await getUsername(credentials);
        //if report size is more than 10 show info message
        if (reports.length > 10) {
            onOpenInfoToast("You are downloading multiple reports which may take some time. If it fails please reduce the number of reports.")
        }
        const inputParams = {
            "reportIds": reports.map(r => r['Report Id']),
            "requesterId": (user && user.username) || "",
            "reportFormatType": downloadType,
            "reportHeaderType": "Header",
            "reportCompressionType": "NoCompression",
            "allowInvalidReports": true,
            "getData": !!!DIRECT_ACCESSIBLE_STAGES.includes(stageConfig.STAGE),
            "useXlsx": useXlsx
        }
        const data = await downloadBatchReports(credentials.token, inputParams)
        if (data && data.errorMessage) {
            dispatchError(data)
            return;
        }
        saveFile(reports, data, DOWNLOAD_TYPES.BATCH);
    }
    const handleDownload = async (report, downloadType = DOWNLOAD_TYPES.TEXT, useXlsx = false) => {
        try {
            setDownloadState(true)
            loaderState(true)
            if (downloadType == DOWNLOAD_TYPES.ZIP) {
                await makeDownloadAPICall(report, downloadType)
            } else {
                if (report != null) {
                    await makeDownloadAPICall(report, downloadType, useXlsx, false)
                    return;
                }
                const reportIds = _.keys(selected).filter(s => {
                    if (selected[s] === true) return s
                })
                if (!reportIds || reportIds.length === 0) {
                    dispatchError("Please select the report(s) which you want to download!");
                } else {
                    const filteredReports = reports.filter(r => reportIds.includes(r[renameKeys.reportId]))
                    if (!filteredReports) {
                        dispatchError("No report matched for downloading!");
                    } else {
                        if (filteredReports.length === 1)
                            await makeDownloadAPICall(filteredReports[0], downloadType, useXlsx, false)
                        else
                            await makeBatchDownloadAPICall(filteredReports, downloadType, useXlsx)
                    }
                }
            }
        } catch (err) {
            console.error(err)
            dispatchError("Error while downloading reports, Try again!")
        } finally {
            setDownloadState(false)
            loaderState(false)
        }
        return false;
    };

    const [openExportModal, setOpenExportModal, openExportModalRef] = useState(false)
    const handleExport = async () => {
        try {
            setDownloadState(true)
            loaderState(true)
            const selectedReportIds = _.keys(selected).filter(s => {
                if (selected[s] === true) return s
            })
            const filteredReports = reports.filter(r => selectedReportIds.includes(r[renameKeys.reportId]))
            const reportToExport = _.isEmpty(filteredReports) ? reports : filteredReports
            if (!reportToExport) {
                dispatchError("No report matched for exporting!");
            } else {
                //perform export action on `filteredReports`
                setOpenExportModal(true);
                await DELAY(1500);
                const eData = reportToExport.map(r => {
                    const temp = {
                        "Report Id": r[renameKeys.reportId],
                        "Report Type": r[renameKeys.reportType],
                        "Begin Date": r[renameKeys.beginDate],
                        "End Date": r[renameKeys.endDate],
                        "Vendor Code": r[renameKeys.vendorCode],
                        "MarketPlaceId": r[renameKeys.marketPlaceId],
                        "Is Valid": r[renameKeys.isValid],
                        "Amount": r[renameKeys.sumOfCosts],
                        "Currency": r[renameKeys.sumOfCostsCurrencyCode],
                        "Created On": r[renameKeys.reportDate],
                    }
                    if (showDetails) {
                        temp['Metadata'] = r[renameKeys.metadata]
                    }
                    return temp
                })
                await makeCsv(eData, getExportFileName())
            }
        } catch (err) {
            console.error(err)
            dispatchError("Error while exporting table data, Try again!")
        } finally {
            setDownloadState(false)
            loaderState(false)
        }
        return false;
    };

    const fetchFile = async (url) => {
        const rows = []
        return new Promise((resolve, reject) => {
            readRemoteFile(url, {
                step: (row) => {
                    rows.push(row.data)
                    if (rows.length > VIEW_ROW_LIMIT)
                        resolve({rows, limitReached: true})
                },
                complete: (results) => {
                    resolve({rows, limitReached: false})
                },
                error(err, file) {
                    reject(err)
                }
            })
        })
    }
    //view handler
    const [openModal, setOpenModal, openModalRef] = useState(false);
    const [modalReport, setModalReport, modalReportRef] = useState(null);
    const [modalData, setModalData, modalDataRef] = useState(null);
    const viewReport = async (report) => {
        try {
            setDownloadState(true)
            loaderState(true)
            setModalReport(report)
            setModalData(null)
            //make call to get the report in txt format as blob
            const data = await makeDownloadAPICall(report, DOWNLOAD_TYPES.TEXT, false, true)
            if (data && data.url) {
                const fileData = await fetchFile(data.url)
                if (!fileData)
                    throw  new Error("Error while opening the report.")
                try {
                    const rows = [fileData.rows.slice(0, VIEW_ROW_LIMIT).map(r => r.join("\t")).join("\n")]
                    setModalData({
                        rows,
                        strippedRows: fileData.limitReached,
                        data,
                        report,
                        downloadType: 'Text',
                        type: REPORT_MODAL_TYPES.VIEW_REPORT
                    })
                    setOpenModal(true)
                } catch (err) {
                    dispatchError("Error while opening the report!")
                }

            }
        } catch (err) {
            console.error(err)
            dispatchError("Error while fetching reports, Try again!")
        } finally {
            setDownloadState(false)
            loaderState(false)
        }
    }


    //inline disbursement viewer
    const fetchDisbursement = async (report) => {
        try {
            setDownloadState(true)
            loaderState(true)
            setModalReport(report)
            setModalData(null)
            if (_.isEmpty(report[renameKeys.reportType]))
                dispatchError("Report Type cannot be empty.")
            else if (_.isEmpty(report[renameKeys.vendorCode]))
                dispatchError("Vendor Code cannot be empty.")
            else {
                const gl = getGlForReportType(claimsData, [report])
                const credentials = await refreshMidway();
                if (!credentials)
                    throw new Error("Invalid Credentials.")
                const inputParams = {
                    vendorCode: report[renameKeys.vendorCode],
                    startDate: Math.floor((new Date(new Date().getFullYear(), new Date().getMonth(), 1)).valueOf() / 1000),
                    endDate: Math.floor(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 5).valueOf() / 1000),
                    marketPlaceIds: getMarketPlaceFromName(claimsData, report[renameKeys.marketPlaceName]),
                    glProductGroups: gl
                }
                const data = await getAccumulatingDisbursement(credentials.token, inputParams)
                if (data && data.errorMessage) {
                    dispatchError(data)
                    return;
                }
                setModalData({data, type: REPORT_MODAL_TYPES.ACCUMULATED_DISBURSEMENT})
                setOpenModal(o => !o)
            }
        } catch (err) {
            console.error(err)
            dispatchError("Error while fetching fetching accumulated disbursement, Try again!")
        } finally {
            setDownloadState(false)
            loaderState(false)
        }
    }

    const downloadMenuRef = useRef()
    const [openMenu, setOpenMenu] = useState(false)
    const openMenuButton = useCallback(() => setOpenMenu(true), [])
    const onMenuClose = useCallback(() => setOpenMenu(false), [])

    const [openStatusChangeModal, setOpenStatusChangeModal, openStatusChangeModalRef] = useState(false)
    const [statusChangeApiCalled, setStatusChangeApiCalled, statusChangeApiCalledRef] = useState(false)
    const [statusChangeApiMakingCall, setStatusChangeApiMakingCall, statusChangeApiMakingCallRef] = useState(false)
    const [statusChangeApiSuccess, setStatusChangeApiSuccess, statusChangeApiSuccessRef] = useState(false)
    const [statusChangeProgressText, setStatusChangeProgressText, statusChangeProgressTextRef] = useState("")
    const [isReportValid, setIsReportValid, isReportValidRef] = useState(false);
    const [userValidReports, setUserValidReports, userValidReportsRef] = useState({});

    const [allowedFormatTypes, setAllowedFormatTypes, allowedFormatTypesRef] = useState(defaultFormatTypes)

    const [infoToasts, setInfoToasts] = useState([])
    const onCloseInfoToast = useCallback(
        id => setInfoToasts(infoToasts.filter(t => t.id !== id)),
        [infoToasts]
    )
    let dummyId = 0
    const onOpenInfoToast = useCallback(
        (message) => setInfoToasts(infoToasts.concat({id: `${++dummyId}`, message, timeout: 3000})),
        [infoToasts]
    )


    return (
        !hasGlAccess ? (
                <Alert
                    type="error"
                    size="large"
                >Looks Like you don't have access to any report type. You need business specific permission to access
                    reports. Please contact <Link onClick={() => setOpenFeedback(true)}>rta-team</Link> for any queries or
                    use the Help Menu.
                </Alert>
            ) :
            !reports ? (
                <Alert
                    type="informational"
                    size="large"
                >Apply the filters to view the reports.</Alert>
            ) : reports.length == 0 ?
                (
                    <Alert
                        type="warning"
                        size="large"
                    >We were not able to find any reports based on your inputs.<br/>Tip: Some reports are generated on
                        last day of previous month, try
                        adjusting the begin and end date with 1-2 days buffer.</Alert>
                )
                : (
                    <React.Fragment>
                        <GeneralConfirmDialog openModal={openExportModal} setOpenModal={setOpenExportModal}
                                              isDarkMode={themeContext.isDarkMode}/>
                        <ConfirmStatusChange reportData={modalReport} openStatusChangeModal={openStatusChangeModal}
                                             setOpenStatusChangeModal={setOpenStatusChangeModal}
                                             stageConfig={stageConfig} isReportValid={isReportValid}
                                             setUserValidReports={setUserValidReports}
                                             userValidReports={userValidReports}
                                             userValidReportsRef={userValidReportsRef}
                                             onOpenToast={dispatchError} statusChangeApiCalled={statusChangeApiCalled}
                                             statusChangeApiMakingCall={statusChangeApiMakingCall}
                                             statusChangeApiSuccess={statusChangeApiSuccess}
                                             statusChangeProgressText={statusChangeProgressText}
                                             setStatusChangeApiCalled={setStatusChangeApiCalled}
                                             setStatusChangeApiMakingCall={setStatusChangeApiMakingCall}
                                             setStatusChangeApiSuccess={setStatusChangeApiSuccess}
                                             setStatusChangeProgressText={setStatusChangeProgressText}/>
                        {/*modal for showing the report transactions*/}
                        <ModalViewComponent openModal={openModal} setOpenModal={setOpenModal} modalData={modalData}
                                            loaderState={loaderState} onOpenToast={dispatchError} makingCall={makingCall}
                                            downloadState={downloadState} modalReport={modalReport} saveFile={saveFile}
                                            handleDownload={handleDownload}/>

                        <Column className={"small"} width="100%" spacing="small" alignmentHorizontal="right">

                            <DisbursementTable data={disbursementData} loaderState={loaderState}
                                               canTriggerLive={canTriggerLive}
                                               onOpenToast={dispatchError} isSeparateTable={true} claimsData={claimsData}>
                            </DisbursementTable>

                            <Row width="100%" widths={["grid-12"]} alignmentHorizontal="left">
                                <TableActionBar widths={["30%", "70%"]}>
                                    <Row width="100%" alignmentHorizontal="left">
                                            <span>
                                            <strong>{checkedCount}</strong>
                                                {" "}
                                                {` report${checkedCount === 1 ? "" : "s"} `}
                                                selected, Loaded <strong>{reports.length}</strong> {`report${checkedCount === 1 ? "" : "s"} `}
                                            </span>
                                    </Row>
                                    <Row width="100%" alignmentHorizontal="right">
                                        {allowedFormatTypes.includes("text") &&
                                        <Button size="small" disabled={downloadState || loadMoreState} type="tertiary"
                                                onClick={() => handleDownload(null, 'Text')}>Download
                                            Text</Button>}
                                        <ButtonGroup type="outline" size="small">
                                            {(allowedFormatTypes.includes("csv") || allowedFormatTypes.includes("excel")) &&
                                            <ButtonOption size="small" disabled={downloadState || loadMoreState}
                                                          type="tertiary"
                                                          onClick={() => handleDownload(null,
                                                              allowedFormatTypes.includes("csv") ? DOWNLOAD_TYPES.CSV : DOWNLOAD_TYPES.EXCEL, false)}
                                            >Download CSV</ButtonOption>}
                                            <ButtonOption size="small" disabled={downloadState || loadMoreState}
                                                          type="tertiary"
                                                          ref={downloadMenuRef}
                                                          onClick={openMenuButton}>▼</ButtonOption>

                                        </ButtonGroup>
                                        <Menu
                                            anchorNode={downloadMenuRef.current}
                                            open={openMenu}
                                            position="bottom"
                                            onClose={onMenuClose}
                                        >
                                            {allowedFormatTypes.includes("csv") && <MenuItem
                                                onClick={() => {
                                                    handleDownload(null, DOWNLOAD_TYPES.CSV, false);
                                                    setOpenMenu(false);
                                                }}>csv</MenuItem>}
                                            {allowedFormatTypes.includes("excel") && <MenuItem
                                                onClick={() => {
                                                    handleDownload(null, DOWNLOAD_TYPES.EXCEL, false);
                                                    setOpenMenu(false);
                                                }}>xls</MenuItem>}
                                            {allowedFormatTypes.includes("excel") && <MenuItem
                                                onClick={() => {
                                                    handleDownload(null, DOWNLOAD_TYPES.EXCEL, true);
                                                    setOpenMenu(false);
                                                }}>xlsx</MenuItem>}
                                        </Menu>
                                        <Button size="small" disabled={downloadState || loadMoreState} type="tertiary"
                                                onClick={() => handleExport()}>
                                            Export Table</Button>
                                    </Row>
                                </TableActionBar>
                            </Row>

                            <Row width="100%" widths={["grid-12"]} alignmentHorizontal="left">
                                <div className={'small'} style={{
                                    overflowX: "auto",
                                    maxWidth: "100%",
                                    padding: "5px",
                                }}>
                                    <Table
                                        width="100%"
                                        headerRows={1}
                                        spacing="small"
                                        showDividers={true}
                                        showStripes={false}
                                        sortColumn={sortColumn}
                                        sortDirection={sortDirection}
                                        onSort={onSort}
                                        fixHeaderRows={false}
                                    >
                                        {/*table headers*/}
                                        <TableRow
                                            key={'header'}
                                            highlightOnHover={true}>
                                            <TableCell key={'select'} width="50px">
                                                <Checkbox
                                                    checked={checkedCount > 0}
                                                    onChange={onChangeHeader}
                                                    checked={allCheckedInCurrent}
                                                    iconTokens={headerCheckBoxIcon === "minusIconTokens" ? minusIconTokens : undefined}
                                                />
                                                Select
                                            </TableCell>
                                            {_.keys(reports[0]).map((d) => {
                                                if (extraItems.includes(d))
                                                    return
                                                if (!hiddenItems.includes(d) || (hiddenItems.includes(d) && showDetails))
                                                    return <TableCell width="50px" key={d}
                                                                      sortColumn={d}>{d}</TableCell>
                                            })}
                                        </TableRow>

                                        {/*table rows*/}
                                        {reports.slice(firstVisibleIndex, lastVisibleIndex).map((row) => (
                                            <TableRow key={row['Report Id']} highlightOnHover={true}>
                                                {/*checkbox button : if the report is of s3 type : [renameKeys.isS3Report] will be empty*/}
                                                <TableCell key={'select'}>
                                                    {shouldReportBeAllowedToDownloadInOtherFormats(row) ? (
                                                        <Checkbox checked={selected[row[renameKeys.reportId]]}
                                                                  onChange={onChangeRow(row[renameKeys.reportId])}
                                                        />
                                                    ) : ''
                                                    }
                                                </TableCell>

                                                {/*all other columns*/}
                                                {_.keys(row).map((d) => {
                                                    if (extraItems.includes(d))
                                                        return
                                                    if (!hiddenItems.includes(d) || (hiddenItems.includes(d) && showDetails)) {
                                                        return <TableCell key={d}>
                                                            {
                                                                d === renameKeys.isS3Report ?
                                                                    (row[renameKeys.formatTypes].includes(DOWNLOAD_TYPES.ZIP.toLowerCase()) ?
                                                                            <Button type="icon" disabled={downloadState}
                                                                                    size="small"
                                                                                    onClick={() => handleDownload(row, DOWNLOAD_TYPES.ZIP)}>
                                                                                <Icon tokens={downloadLargeTokens}/>
                                                                            </Button>
                                                                            : <div>
                                                                                <Button type="icon"
                                                                                        disabled={downloadState}
                                                                                        size="small"
                                                                                        onClick={() => viewReport(row)}>
                                                                                    <Icon tokens={viewTokens}/>
                                                                                </Button>
                                                                            </div>
                                                                    )
                                                                    :
                                                                    d === renameKeys['Acc. Amount'] ?
                                                                        !_.isEmpty(getGlForReportType(claimsData, [row], null)) ?
                                                                            <Button type="icon" disabled={downloadState}
                                                                                    size="small"
                                                                                    onClick={() => fetchDisbursement(row)}>
                                                                                <Icon tokens={viewTokens}/>
                                                                            </Button>
                                                                            : ""
                                                                        :
                                                                        d === renameKeys.isValid && claimsData.hasUpdatePermission ?
                                                                            <Toggle
                                                                                checked={row[renameKeys.reportId] in userValidReportsRef.current ? userValidReportsRef.current[row[renameKeys.reportId]] : row[renameKeys.isValid] === "Yes"}
                                                                                onChange={() => {
                                                                                    setModalReport(row);
                                                                                    setStatusChangeApiCalled(false)
                                                                                    setStatusChangeApiMakingCall(false)
                                                                                    setStatusChangeApiSuccess(false)
                                                                                    setStatusChangeProgressText("")
                                                                                    setIsReportValid(row[renameKeys.reportId] in userValidReportsRef.current ? userValidReportsRef.current[row[renameKeys.reportId]] : row[renameKeys.isValid] === "Yes")
                                                                                    setOpenStatusChangeModal(true);
                                                                                }}
                                                                                size="medium"
                                                                            />
                                                                            : row[d]
                                                            }
                                                        </TableCell>
                                                    }
                                                })}
                                            </TableRow>
                                        ))}

                                    </Table>
                                </div>
                            </Row>

                            <Row width="100%" widths={["30%", "70%"]}>
                                <Row width="100%" alignmentHorizontal="left">
                                    <Toggle checked={showDetails} onChange={setShowDetails}>Detailed View</Toggle>
                                </Row>
                                <Row width="100%" alignmentHorizontal="right">
                                    <Pagination
                                        showSkipArrows={true}
                                        numberOfPages={numberOfPages}
                                        onChange={setCurrentPage}
                                        currentPage={currentPage}
                                        onChange={paginationHandler}
                                    />
                                    {hasMoreRef.current ?
                                        <Button onClick={onLoadMoreHandler} disabled={downloadState || loadMoreState}
                                                type="tertiary"
                                                size="small">
                                            Load More
                                        </Button>
                                        : ''}
                                </Row>
                            </Row>

                        </Column>
                        <Toaster toasts={infoToasts} onCloseToast={onCloseInfoToast}
                                 alignmentHorizontal="center"
                        >
                            {toast => <Alert toast={true}
                                             type="informational"
                                             size="large"
                            >{toast.message}</Alert>}
                        </Toaster>

                    </React.Fragment>

                )


    );
};

export const TableSection = connector(TableSectionComponent);