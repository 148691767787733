import Masthead, {MastheadTitle, MastheadLink} from "@amzn/meridian/masthead";
import React, {useContext, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import Menu, {MenuItem} from "@amzn/meridian/menu";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Link from "@amzn/meridian/link";
import {
    TT_LINK,
    FAQ_LINK,
    WEBSITE_URLS,
    PAGE_LOCATIONS, RegionTypes, PERM_MGMT
} from "src/constants/app";
import {getCurrentStageConfig} from "src/constants/stage";
import chevronDown from "@amzn/meridian-tokens/base/icon/chevron-down-small";
import chatTokens from "@amzn/meridian-tokens/base/icon/chat"
import cogTokens from "@amzn/meridian-tokens/base/icon/cog"
import {Feedback} from "./Feedback"
import {v4 as uuidv4} from 'uuid';
import '../reports/styles/styles.css'
import Alert from "@amzn/meridian/alert"
import {SettingsModal} from "src/components/layouts/SettingsModal";
import {useSelector} from "react-redux";
import {Claims} from "src/reducers/claims/types";
import {ThemeContext} from "src/context/ThemeContext";
import Toggle from "@amzn/meridian/toggle";
import asleepTokens from "@amzn/meridian-tokens/base/icon/asleep"
import Coachmark from "@amzn/meridian/coachmark";
import Column from "@amzn/meridian/column";
import {useStateWithLocalStorage} from "src/util/localStorage";

const Header: React.FunctionComponent = () => {
    let history = useHistory();
    let location = useLocation();
    let helpRef = useRef();
    let regionRef = useRef();
    const [helpOpen, setHelpOpen] = useState(false);
    const [regionOpen, setRegionOpen] = useState(false);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [isDarkModeDismissed, setDarkModeDismissed] = useStateWithLocalStorage('isDarkModeDismissed', false);
    const themeContext = useContext(ThemeContext);

    const stageConfig = getCurrentStageConfig();
    const openInNewTab = (e: string) => {
        window.open(e, "_blank", "noopener,noreferrer");
    };
    const claimsSelector = (state: { claims: Claims }) => state.claims;
    const claimsData = useSelector(claimsSelector);

    return (
        <>
            <Masthead size="medium">
                <MastheadTitle href={PAGE_LOCATIONS.HOME} onClick={history.push}>
                    <Text type="h200">Digital Vendor Services</Text>
                </MastheadTitle>
                <MastheadLink
                    href="/"
                    selected={location.pathname === PAGE_LOCATIONS.HOME}
                    onClick={history.push}
                >
                    Home
                </MastheadLink>
                <MastheadLink
                    href={PAGE_LOCATIONS.REPORTS}
                    onClick={history.push}
                    selected={location.pathname === PAGE_LOCATIONS.REPORTS}
                >
                    Vendor Central Reports
                </MastheadLink>
                <MastheadLink
                    href={PAGE_LOCATIONS.VISS_SERVICE}
                    onClick={history.push}
                    selected={location.pathname === PAGE_LOCATIONS.VISS_SERVICE}
                >
                    VendorIdentity Service
                </MastheadLink>
                <MastheadLink
                    href={PAGE_LOCATIONS.BUCKETS_INFO}
                    onClick={history.push}
                    selected={location.pathname === PAGE_LOCATIONS.BUCKETS_INFO}
                >
                    MonthEnd Reconciliation
                </MastheadLink>

                <MastheadLink onClick={() => setHelpOpen(true)}>
                    <Text ref={helpRef}>
                        Help
                        <Icon tokens={chevronDown}> </Icon>
                    </Text>
                    <HelpMenu
                        anchor={helpRef}
                        isOpen={helpOpen}
                        setOpen={setHelpOpen}
                        setFeedback={setOpenFeedback}
                        history={history}
                    >
                    </HelpMenu>
                </MastheadLink>
                {!isDarkModeDismissed &&
                <Coachmark popoverPosition="bottom">
                    <Column width="200px">
                        <Text type="h100">Visit the dark side!</Text>
                        <Text>Dark mode is now supported across the entire site! Use this toggle on any page to
                            switch between dark and light mode</Text>
                        <Link onClick={() => setDarkModeDismissed(true)}>Dismiss</Link>
                    </Column>
                </Coachmark>}
                <Toggle checked={themeContext.isDarkMode} onChange={themeContext.setDarkMode}>
                    <Icon tokens={asleepTokens}/>
                </Toggle>
                {claimsData && claimsData.isInternal &&
                <Button type="icon" onClick={() => setOpenSettings(true)}>
                    <Icon tokens={cogTokens}/>
                </Button>
                }
                <Button type="icon" onClick={() => setOpenFeedback(true)}>
                    <Icon tokens={chatTokens}/>
                    <Text>Feedback</Text>
                </Button>
                <Feedback key={uuidv4()} openFeedback={openFeedback} setOpenFeedback={setOpenFeedback}
                          isDarkMode={themeContext.isDarkMode}/>
                {claimsData && claimsData.isInternal &&
                <SettingsModal key={uuidv4()} openSettings={openSettings} setOpenSettings={setOpenSettings}/>}
                <Link
                    ref={regionRef}
                    onClick={() => setRegionOpen(true)}
                    type="secondary"
                >
                    Region: {stageConfig.REALM}
                    <Icon tokens={chevronDown}> </Icon>
                </Link>
                <RegionMenu
                    anchor={regionRef}
                    isOpen={regionOpen}
                    setOpen={setRegionOpen}
                    setFeedback={setOpenFeedback}
                    history={history}
                >

                </RegionMenu>
            </Masthead>
        </>
    );
};

interface ImenuProps {
    isOpen: boolean;
    anchor: React.MutableRefObject<any>;
    setOpen: (a: boolean) => any;
    setFeedback: (a: boolean) => any;
    history: any;
}

const HelpMenu: React.FunctionComponent<ImenuProps> = ({
                                                           anchor,
                                                           isOpen,
                                                           setOpen,
                                                           setFeedback,
                                                           history
                                                       }) => {
    return (
        <Menu
            anchorNode={anchor.current}
            open={isOpen}
            position="bottom"
            onClose={() => {
                setOpen(false);
            }}
        >
            <MenuItem href={FAQ_LINK} target="_blank" rel="noopener noreferrer">
                About Us
            </MenuItem>
            <MenuItem href={PERM_MGMT} target="_blank" rel="noopener noreferrer">
                Permission Management
            </MenuItem>
            <MenuItem target="_blank" rel="noopener noreferrer" onClick={() => setFeedback(true)}>
                Report an Issue
            </MenuItem>
        </Menu>
    );
};

const RegionMenu: React.FunctionComponent<ImenuProps> = ({
                                                             anchor,
                                                             isOpen,
                                                             setOpen,
                                                             setFeedback,
                                                             history
                                                         }) => {
    const stageConfig = getCurrentStageConfig();
    let params = new URLSearchParams(history.location.search);
    params.delete("marketPlaces")
    let search = params.toString() ? `?${params.toString()}`:''
    return (
        <Menu
            anchorNode={anchor.current}
            open={isOpen}
            position="bottom"
            onClose={() => {
                setOpen(false);
            }}
        >
            <MenuItem href={`${WEBSITE_URLS[stageConfig.STAGE].NA}#${history.location.pathname}${search}`}>NA</MenuItem>
            <MenuItem href={`${WEBSITE_URLS[stageConfig.STAGE].FE}#${history.location.pathname}${search}`}>FE</MenuItem>
            <MenuItem href={`${WEBSITE_URLS[stageConfig.STAGE].EU}#${history.location.pathname}${search}`}>EU</MenuItem>
            <MenuItem href={`${WEBSITE_URLS[stageConfig.STAGE].CN}#${history.location.pathname}${search}`}>CN</MenuItem>
        </Menu>
    );
};


export default Header;