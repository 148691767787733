import React, {useCallback, useContext, useEffect} from "react";
import useState from 'react-usestateref'
import Select, {SelectOption, SelectOptionGroup} from "@amzn/meridian/select";
import Row from "@amzn/meridian/row";
import {useSelector} from "react-redux";
import Column from "@amzn/meridian/column";
import DatePicker from "@amzn/meridian/date-picker"
import Input from "@amzn/meridian/input"
import Toggle from "@amzn/meridian/toggle"
import Button from "@amzn/meridian/button"
import Alert from "@amzn/meridian/alert"
import initialMetricsPublisher from "../../metrics";
import {TableSection} from "src/components/reports/TableData";
import {fetchBatchReports, fetchReportById, getAccumulatingDisbursement} from "src/apis";
import {refreshMidway} from "src/authentication/midwayTokenRetriever";
import Loader from "@amzn/meridian/loader";
import Text from "@amzn/meridian/text";
import {getCurrentStageConfig} from "src/constants/stage";
import {
    canTriggerLiveReport,
    formatReports,
    getGlForReportType,
    renameKeys
} from "src/components/reports/ReportConstants";
import './styles/styles.css'
import Link from "@amzn/meridian/link";
import {v4 as uuidv4} from "uuid";
import {Feedback} from "src/components/layouts/Feedback";
import Expander from "@amzn/meridian/expander";
import {useHistory, useLocation} from "react-router-dom";
import {CN_REGION, PAGE_LOCATIONS, R2MS_REPORTS_LIMIT, REPORT_ID_QUERY_PARAM} from "src/constants/app";
import {ThemeContext} from "src/context/ThemeContext";
import {ErrorToast} from "src/components/common/ErrorToast";
import {connect} from "react-redux";
import {addServiceError} from "src/reducers/app/actions";

const mapDispatch = {
    dispatchError: (value) => addServiceError(value)
};

const connector = connect(null, mapDispatch);

export const SideBarComponent = ({dispatchError}) => {
        const search = useLocation().search;
        const urlParams = new URLSearchParams(search);
        const history = useHistory();

        const claimsSelector = (state) => state.claims;
        const claimsData = useSelector(claimsSelector);
        const stageConfig = getCurrentStageConfig();
        const themeContext = useContext(ThemeContext);

        const [hasGlAccess, setHasGlAccess, hasGlAccessRef] = useState(true)
        const [reportType, setReportType, reportTypeRef] = useState([])
        const [vendorCode, setVendorCode, vendorCodeRef] = useState('')
        const [startDate, setStartDate, startDateRef] = useState('')
        const [endDate, setEndDate, endDateRef] = useState('')
        const [shadowChecked, setShadowChecked, shadowCheckedRef] = useState(false)
        const [invalidChecked, setInvalidChecked, invalidCheckedRef] = useState(true)
        const [marketPlace, setMarketPlace, marketPlaceRef] = useState([])
        const [shadowType, setShadowType, shadowTypeRef] = useState('')
        const [tableData, setTableData, tableDataRef] = useState([])
        const [showReportsState, setShowReportsState, showReportsStateRef] = useState(false)
        const [loadMoreState, setLoadMoreState, loadMoreStateRef] = useState(false)
        const [makingCall, setMakingCall, makingCallRef] = useState(false)
        const [hasMore, setHasMore, hasMoreRef] = useState(false)

        const [selected, setSelected, selectedRef] = useState({})
        const [currentPage, setCurrentPage, currentPageRef] = useState(1)
        const [reportData, setReportData, reportDataRef] = useState([])
        const [headerCheckBoxIcon, setHeaderCheckBoxIcon, headerCheckBoxIconRef] = useState(undefined)
        const [allCheckedInCurrent, setAllCheckedInCurrent, allCheckedInCurrentRef] = useState(false)

        const [disbursementData, setDisbursementData, disbursementDataRef] = useState([])
        const [canTriggerLive, setCanTriggerLive, canTriggerLiveRef] = useState(false)
        const [openFeedback, setOpenFeedback] = useState(false);
        const [openExtras, setOpenExtras] = useState(false);
        const [reportId, setReportId, reportIdRef] = useState('');

        const [reportTypeSearchQuery, setReportTypeSearchQuery] = useState()
        const searchRegExp = new RegExp(_.escapeRegExp(reportTypeSearchQuery), "i")
        // format of each entry : {id , name, reportTypes :[]}
        const matchedOptions = claimsData.reportTypes.map(a => {
            const matchedTypes = a.reportTypes.filter(r => !reportTypeSearchQuery || searchRegExp.test(r))
            if (matchedTypes && matchedTypes.length > 0) {
                return {
                    id: a.id, name: a.name, reportTypes: matchedTypes
                }
            }
            return null
        }).filter(a => a)

        const shouldLiveReportBeTriggered = (vendorCode, reports) => {
            try {
                const filteredReports = reports.filter(r => r[renameKeys.vendorCode] == vendorCode)
                return canTriggerLiveReport(filteredReports)
            } catch (err) {
                console.error(`Error while checking for shouldLiveReportBeTriggered : ${err}`)
            }
        }

        const fetchDisbursement = async (credentials, vendorCode, marketPlaceId, reportType, reports) => {
            try {
                const filteredReports = reports.filter(r => r[renameKeys.vendorCode] == vendorCode)
                const gl = getGlForReportType(claimsData, filteredReports, null)
                if (_.isEmpty(gl)) return []
                const params = {
                    vendorCode: vendorCode,
                    startDate: Math.floor((new Date(new Date().getFullYear(), new Date().getMonth(), 1)).valueOf() / 1000),
                    endDate: Math.floor(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 5).valueOf() / 1000),
                    marketPlaceIds: _.isEmpty(marketPlaceId) ? [] : marketPlaceId,
                    glProductGroups: gl
                }
                const data = await getAccumulatingDisbursement(credentials.token, params)
                if (data && data.errorMessage) return []
                return data
            } catch (err) {
                console.error(`Error while fetching accumulated disbursement : ${err}`)
            }
        }
        const selectAllReportTypes = (isSet = true) => {
            const toCheck = []
            if (isSet) {
                matchedOptions.map(gl => {
                    gl.reportTypes.map(item => toCheck.push(item))
                })
            }
            setReportType(toCheck)
            return toCheck
        }
        const isAllReportTypeSelected = () => {
            const availableTypes = matchedOptions.reduce((total, r) => {
                total += r.reportTypes.length
                return total
            }, 0);
            return availableTypes === reportTypeRef.current.length
        }
        const selectAllMarketPlaces = (isSet = true) => {
            setMarketPlace(isSet ? Object.keys(claimsData.markets) : [])
        }
        const isAllMarketPlaceSelected = () => Object.keys(claimsData.markets).length === marketPlaceRef.current.length

        const resetAllInputs = () => {
            setReportType([])
            setStartDate('')
            setEndDate('')
            setVendorCode('')
            setMarketPlace([])
            setInvalidChecked(true)
            setShadowChecked(false)
            setShadowType('')
            setHasMore(false)
            setAllCheckedInCurrent(false)
            setHeaderCheckBoxIcon(undefined)
            setCurrentPage(1)
            setSelected({})
        }
        const searchByReportId = async () => {
            try {
                setShowReportsState(true)
                setMakingCall(true)
                resetAllInputs()
                const credentials = await refreshMidway();
                if (!credentials)
                    throw new Error("Invalid Credentials.")
                const inputParams = {
                    reportId: reportIdRef.current
                }
                const data = await fetchReportById(credentials.token, inputParams)
                if (data && data.errorMessage) {
                    dispatchError(data);
                    return;
                }
                data.reports = _.uniqBy(formatReports(data.reports, stageConfig), 'Report Id')
                setReportData(data.reports)
                setTableData(data)
            } catch (err) {
                console.error(err)
                dispatchError("Error while fetching reports, Try again!");
            } finally {
                setShowReportsState(false)
                setMakingCall(false)
            }
        }
        const onClick = useCallback(async (fromUrl = false) => {
            try {
                setShowReportsState(true)
                setMakingCall(true)
                const actionMetricsPublisher =
                    initialMetricsPublisher.newChildActionPublisherForMethod("buttonCLicked");
                actionMetricsPublisher.publishCounterMonitor('show-report', 1);
                //search by report id
                if (!_.isEmpty(reportIdRef.current)) {
                    history.push(`${PAGE_LOCATIONS.REPORTS}?${REPORT_ID_QUERY_PARAM}=${reportIdRef.current}`)
                    await searchByReportId();
                    return;
                }
                history.push(`${PAGE_LOCATIONS.REPORTS}`)
                if (!_.isEmpty(startDateRef.current) && !_.isEmpty(endDateRef.current) && startDateRef.current > endDateRef.current)
                    dispatchError("Start Date cannot be after end date.");
                else {
                    setDisbursementData([])
                    setCanTriggerLive(false)
                    setReportId("")
                    const credentials = await refreshMidway();
                    if (!credentials)
                        throw new Error("Invalid Credentials.")
                    if (_.isEmpty(reportTypeRef.current)) {
                        const checkedReports = selectAllReportTypes(true)
                        if (checkedReports.length === 0) {
                            setHasGlAccess(false)
                            return;
                        }
                    }
                    if (_.isEmpty(marketPlaceRef.current)) {
                        selectAllMarketPlaces(true)
                    }
                    if (!_.isEmpty(reportTypeRef.current) && reportTypeRef.current.some(r => !(claimsData.reportTypes.map(rr => rr.reportTypes).flat(Infinity)).includes(r))) {
                        dispatchError("You do not have access to the given report type.");
                        return
                    }
                    const inputParams = {
                        reportTypes: isAllReportTypeSelected() && claimsData.isInternal ? null : reportTypeRef.current, // if user belong to internal group and all report type is selected then pass null
                        vendorCodes: _.isEmpty(vendorCodeRef.current) ? null : Array.isArray(vendorCodeRef.current) ? vendorCodeRef.current : vendorCodeRef.current.split(",").map(v => v.trim()),
                        beginDate: !_.isEmpty(startDateRef.current) ? Math.floor(new Date(startDateRef.current).valueOf() / 1000) - 24 * 60 * 60 : null,
                        endDate: !_.isEmpty(endDateRef.current) ? Math.ceil(new Date(endDateRef.current).valueOf() / 1000) + 60 * 60 * 60 : null,
                        marketPlaces: marketPlaceRef.current,
                        includeInvalid: invalidCheckedRef.current,
                        isOnlyShadow: shadowCheckedRef.current,
                        shadowType: _.isEmpty(shadowTypeRef.current) ? null : shadowTypeRef.current,
                        start: 0,
                        limit: R2MS_REPORTS_LIMIT()
                    }
                    history.push({
                        pathname: PAGE_LOCATIONS.REPORTS,
                        search: `?${new URLSearchParams({
                            ...inputParams,
                            beginDate: startDateRef.current,
                            endDate: endDateRef.current
                        })}`
                    })
                    const [data] = await Promise.all([fetchBatchReports(credentials.token, inputParams)])
                    if (data && data.errorMessage) {
                        dispatchError(data);
                    }
                    data.reports = _.uniqBy(formatReports(data.reports, stageConfig), renameKeys.reportId)
                    let cageData = []
                    data['inputParams'] = inputParams
                    setSelected({})
                    setAllCheckedInCurrent(false)
                    setHeaderCheckBoxIcon(undefined)
                    setCurrentPage(1)
                    setReportData(data.reports)
                    setTableData(data)
                    // if vendor code is present make call to fetch accumulated disbursement
                    if (stageConfig.AWS_REGION !== CN_REGION
                        && !_.isEmpty(data.reports)
                        && !_.isEmpty(vendorCodeRef.current) && (Array.isArray(vendorCodeRef.current) ? vendorCodeRef.current : vendorCodeRef.current.split(",").map(v => v.trim())).length === 1
                    ) {
                        cageData = await fetchDisbursement(credentials, vendorCodeRef.current, marketPlaceRef.current, reportTypeRef.current, data.reports)
                        setCanTriggerLive(shouldLiveReportBeTriggered(vendorCodeRef.current, data.reports))
                    }
                    setDisbursementData(cageData)
                    setHasMore(data.hasMore === true)
                }
            } catch (err) {
                console.error(err)
                dispatchError("Error while fetching reports, Try again!");
            } finally {
                setShowReportsState(false)
                setMakingCall(false)
            }
        }, [])
        const onLoadMoreHandler = useCallback(async () => {
            try {
                setLoadMoreState(true)
                setMakingCall(true)
                const credentials = await refreshMidway();
                const inputParams = tableDataRef.current.inputParams
                inputParams['start'] = inputParams['start'] + inputParams['limit']
                const data = await fetchBatchReports(credentials.token, inputParams)
                if (data && data.errorMessage) {
                    dispatchError(data);
                } else {
                    data.reports = formatReports(data.reports, stageConfig)
                    data['reports'] = _.uniqBy([...tableDataRef.current.reports, ...data.reports], renameKeys.reportId)
                }
                data['inputParams'] = inputParams
                setReportData(data.reports)
                setTableData(data)
                setHasMore(data.hasMore === true)
            } catch (err) {
                console.error(err)
                dispatchError("Error while fetching reports, Try again!");
            } finally {
                setLoadMoreState(false)
                setMakingCall(false)
            }
        }, [])

        //effect handler to check query params for report id
        useEffect(() => {
            if (urlParams.get(REPORT_ID_QUERY_PARAM) != null) {
                setReportId(urlParams.get(REPORT_ID_QUERY_PARAM))
                setOpenExtras(true)
                searchByReportId()
            } else {
                let canTrigger = false
                if (!_.isEmpty(urlParams.get("reportTypes")) && urlParams.get("reportTypes") !== 'null') {
                    setReportType(urlParams.get("reportTypes").split(","))
                    canTrigger = true;
                }
                if (!_.isEmpty(urlParams.get("vendorCodes")) && urlParams.get("vendorCodes") !== 'null') {
                    setVendorCode(urlParams.get("vendorCodes").split(","))
                    canTrigger = true;
                }
                if (!_.isEmpty(urlParams.get("marketPlaces")) && urlParams.get("marketPlaces") !== 'null') {
                    setMarketPlace(urlParams.get("marketPlaces").split(","))
                    canTrigger = true;
                }
                if (!_.isEmpty(urlParams.get("beginDate")) && urlParams.get("beginDate") !== 'null') {
                    setStartDate(urlParams.get("beginDate"))
                    canTrigger = true;
                }
                if (!_.isEmpty(urlParams.get("endDate")) && urlParams.get("endDate") !== 'null') {
                    setEndDate(urlParams.get("endDate"))
                    canTrigger = true;
                }
                if (urlParams.get("includeInvalid") != null) {
                    setInvalidChecked(urlParams.get("includeInvalid") === 'true')
                    canTrigger = true;
                }
                if (urlParams.get("isOnlyShadow") != null) {
                    setShadowChecked(urlParams.get("isOnlyShadow") === 'true')
                    canTrigger = true;
                }
                if (!_.isEmpty(urlParams.get("shadowType")) && urlParams.get("shadowType") !== 'null') {
                    setShadowType(urlParams.get("shadowType"))
                    canTrigger = true;
                }
                if (canTrigger) {
                    onClick(true)
                }
            }
        }, [])


        return (
            (claimsData.errorMessage != null ?
                    <Alert
                        type="informational"
                        size="large"
                    >{claimsData.errorMessage}
                    </Alert>
                    :
                    <React.Fragment>
                        <Feedback key={uuidv4()} openFeedback={openFeedback} setOpenFeedback={setOpenFeedback}
                                  isDarkMode={themeContext.isDarkMode}/>
                        <ErrorToast></ErrorToast>
                        <Row
                            className={"small"}
                            spacingInset="medium"
                            alignmentVertical="stretch"
                            width="100%"
                            widths={["12%", "88%"]}
                            spacing="450"
                        >
                            <Column heights="fit" alignmentHorizontal="stretch"
                                    alignmentVertical="top"
                                    spacing="medium"
                            >
                                <Select
                                    value={reportType}
                                    placeholder="Select Report Type"
                                    width="100%"
                                    label="Report Type"
                                    onChange={setReportType}
                                    searchQuery={reportTypeSearchQuery}
                                    onSearch={setReportTypeSearchQuery}
                                    popoverMaxHeight={500}
                                    valueLabel={values => {
                                        if (isAllReportTypeSelected() && claimsData.isInternal) {
                                            return `All Report Types`
                                        } else if (values.length === 1) {
                                            return `Selected ${values[0].label}`
                                        } else if (values.length > 1) {
                                            return `${values.length} Selected`
                                        } else {
                                            return ""
                                        }
                                    }}>

                                    {matchedOptions.length > 0 &&
                                    <SelectOption label="" value="links" disabled={true}>
                                        {
                                            () => {
                                                return (
                                                    <Row alignmentHorizontal="justify">
                                                        <Link onClick={() => selectAllReportTypes(true)}
                                                              disabled={isAllReportTypeSelected()}
                                                              type="secondary">Select All</Link>
                                                        <Link onClick={() => selectAllReportTypes(false)}
                                                              type="secondary">Clear</Link>
                                                    </Row>
                                                )
                                            }
                                        }
                                    </SelectOption>}

                                    {matchedOptions && matchedOptions.map(gl => (
                                        <SelectOptionGroup key={gl.id} label={gl.name}>
                                            {gl.reportTypes.map(item => (
                                                <SelectOption value={item} label={item} key={item}/>
                                            ))}
                                        </SelectOptionGroup>
                                    ))}
                                    {!matchedOptions || matchedOptions.length <= 0 ? (
                                        <Column alignmentVertical="center" spacing="300" spacingInset="600">
                                            <Text alignment="center">No results</Text>
                                        </Column>
                                    ) : null}
                                </Select>

                                <DatePicker width="100%" value={startDate} onChange={setStartDate}
                                            locale={stageConfig.DATE.locale} label="Begin Date"/>
                                <DatePicker width="100%" value={endDate} onChange={setEndDate}
                                            locale={stageConfig.DATE.locale} label="End Date"/>
                                <Input
                                    width="100%"
                                    size="xlarge"
                                    value={vendorCode}
                                    onChange={setVendorCode}
                                    type="text"
                                    placeholder="Vendor Code"
                                />

                                <Select
                                    value={marketPlace}
                                    placeholder="Select Marketplace"
                                    width="100%"
                                    label="Marketplace"
                                    onChange={setMarketPlace}
                                    popoverMaxHeight={350}
                                    valueLabel={values => {
                                        if (isAllMarketPlaceSelected()) {
                                            return `All Marketplaces`
                                        } else if (values.length === 1) {
                                            return `Selected ${values[0].label}`
                                        } else if (values.length > 1) {
                                            return `${values.length} Selected`
                                        } else {
                                            return ""
                                        }
                                    }}>
                                    <SelectOption label="" value="links" disabled={true}>
                                        {
                                            () => {
                                                return (
                                                    <Row alignmentHorizontal="justify">
                                                        <Link onClick={() => selectAllMarketPlaces(true)}
                                                              disabled={isAllMarketPlaceSelected()}
                                                              type="secondary">Select All</Link>
                                                        <Link onClick={() => selectAllMarketPlaces(false)}
                                                              type="secondary">Clear</Link>
                                                    </Row>
                                                )
                                            }
                                        }
                                    </SelectOption>
                                    {Object.entries(claimsData.markets).map(([symbol, name]) => (
                                        <SelectOption
                                            key={symbol}
                                            value={symbol}
                                            label={name}
                                        />
                                    ))}
                                </Select>
                                <Select
                                    value={shadowType}
                                    placeholder="Select Shadow Type"
                                    width="100%"
                                    label="Shadow Type"
                                    onChange={setShadowType}>
                                    <SelectOption label="" value="links" disabled={true}>
                                        {
                                            () => {
                                                return (
                                                    <Row alignmentHorizontal="justify">
                                                        <Link onClick={() => setShadowType('')}
                                                              type="secondary">Clear</Link>
                                                    </Row>
                                                )
                                            }
                                        }
                                    </SelectOption>
                                    {claimsData.shadowTypes.map((item, index) => (
                                        <SelectOption
                                            key={index}
                                            value={item}
                                            label={item}
                                        />
                                    ))}
                                </Select>
                                <Expander open={openExtras} onChange={setOpenExtras} title="Extras" type="inline"/>
                                {
                                    openExtras && <>
                                        <Input
                                            width="100%"
                                            size="medium"
                                            value={reportId}
                                            onChange={setReportId}
                                            type="text"
                                            placeholder="Report Id"
                                        />
                                    </>
                                }
                                <Toggle checked={invalidChecked} onChange={setInvalidChecked}>Include Invalid
                                    Reports</Toggle>
                                <Toggle checked={shadowChecked} onChange={setShadowChecked}>List Shadow Reports
                                    Only</Toggle>
                                <Button onClick={onClick} disabled={showReportsState} type="primary" size="medium">Show
                                    Reports</Button>

                                <Text type="b200">* All dates are
                                    in <code>{stageConfig.DATE.format.toUpperCase()}</code> format.</Text>

                                <Text type="b200">* Please filter by vendor code to view accrued royalties for current
                                    month.</Text>

                            </Column>

                            <Column width="100%" heights="fit" alignmentHorizontal="center"
                                    alignmentVertical="top"
                                    spacing="small">
                                {/*loader*/}
                                {makingCall ? (
                                    <div id={'loader'}>
                                        <Loader type="linear"/>
                                    </div>
                                ) : ''}

                                {/*table*/}
                                <TableSection hasGlAccess={hasGlAccess} tableData={tableData}
                                              tableDataRef={tableDataRef} setTableData={setTableData}
                                              loaderState={setMakingCall}
                                              makingCall={makingCall}
                                              selected={selected}
                                              setSelected={setSelected} selectedRef={selectedRef}
                                              currentPage={currentPage} currentPageRef={currentPageRef}
                                              setCurrentPage={setCurrentPage} reportData={reportData}
                                              setReportData={setReportData} reportDataRef={reportDataRef}
                                              headerCheckBoxIcon={headerCheckBoxIcon}
                                              setHeaderCheckBoxIcon={setHeaderCheckBoxIcon}
                                              allCheckedInCurrent={allCheckedInCurrent}
                                              setAllCheckedInCurrent={setAllCheckedInCurrent}
                                              onLoadMoreHandler={onLoadMoreHandler} loadMoreState={loadMoreState}
                                              hasMore={hasMore} hasMoreRef={hasMoreRef}
                                              marketPlaceRef={marketPlaceRef}
                                              claimsData={claimsData} stageConfig={stageConfig}
                                              disbursementData={disbursementData} disbursementDataRef={disbursementDataRef}
                                              setOpenFeedback={setOpenFeedback} canTriggerLive={canTriggerLive}>
                                </TableSection>
                            </Column>

                        </Row>
                    </React.Fragment>
            )
        );
    }
;
export const SideBar = connector(SideBarComponent)