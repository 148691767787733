import Modal from "react-modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, {useCallback, useEffect, useRef} from "react";
import useState from 'react-usestateref'
import Loader from "@amzn/meridian/loader";
import {getLiveReportStatus, publishLiveReport} from "src/apis/rrg";
import {refreshMidway} from "src/authentication/midwayTokenRetriever";
import {useSelector} from "react-redux";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import {getCurrentStageConfig} from "src/constants/stage";
import {useLocation} from "react-router-dom";
import Column from "@amzn/meridian/column";
import {DELAY, PAGE_LOCATIONS} from "src/constants/app";
import {formatReports, LIVE_SUCCESS_STATUS, PARTIAL_SUCCESS_STATUS} from "src/components/reports/ReportConstants";
import {fetchBatchReports} from "src/apis";
import {TableSection} from "src/components/reports/TableData";
import Button from "@amzn/meridian/button";

const _ = require('lodash');


export const RRGLiveReport = (props) => {
    const search = useLocation().search;
    const params = new URLSearchParams(search);
    const data = JSON.parse(params.get("data"));
    const forceSend = params.get("force");

    const claimsSelector = (state) => state.claims;
    const claimsData = useSelector(claimsSelector);
    const stageConfig = getCurrentStageConfig();

    //alert message toasts
    let toastUniqueIds = 0
    const [toasts, setToasts] = useState([])
    const onCloseToast = useCallback(
        id => setToasts(toasts.filter(t => t.id !== id)),
        [toasts]
    )
    const onOpenToast = useCallback(
        (message) => setToasts(toasts.concat({id: `${++toastUniqueIds}`, message, timeout: 3000})),
        [toasts]
    )


    const [viewLoader, setViewLoader] = useState(true);
    const [progressText, setProgressText, progressTextRef] = useState([{"name": "Sending Request..."}]);
    const [errorText, setErrorText, errorTextRef] = useState("");
    const [showForceButton, setShowForceButton, showForceButtonRef] = useState(false);
    const [loadData, setLoadData] = useState(true);
    const [reportData, setReportData, reportDataRef] = useState([])

    //for tables report
    const [tableData, setTableData, tableDataRef] = useState([])
    const [selected, setSelected, selectedRef] = useState({})
    const [currentPage, setCurrentPage, currentPageRef] = useState(1)
    const [headerCheckBoxIcon, setHeaderCheckBoxIcon, headerCheckBoxIconRef] = useState(undefined)
    const [allCheckedInCurrent, setAllCheckedInCurrent, allCheckedInCurrentRef] = useState(false)
    const [disbursementData, setDisbursementData, disbursementDataRef] = useState([])
    const [hasMore, setHasMore, hasMoreRef] = useState(false)
    const [loadMoreState, setLoadMoreState, loadMoreStateRef] = useState(false)
    const [marketPlace, setMarketPlace, marketPlaceRef] = useState([])

    useEffect(() => {

        const getLiveInputParams = (data) => {
            const currentMonth = new Date().getMonth() + 1
            const month = currentMonth < 10 ? `0${currentMonth}` : currentMonth
            const period = `${new Date().getFullYear()}-${month}`
            return {
                startDate: Math.floor((new Date(new Date().getFullYear(), currentMonth - 1, 1)).valueOf()),
                endDate: Math.floor(new Date().valueOf()),
                period: period,
                marketPlaceId: claimsData['marketIds'][data['MarketPlace']],
                purpose: "RoyaltyPaymentShadow",
                businessUnitId: data['Business Id'],
                vendorCode: data['Vendor Code'],
                currency: data['Currency'],
                marketplaceCurrency: data['Currency'],
                preferredCurrency: data['Currency'],
                glCode: Number(data['glProductGroup']),
                bucketId: data['Bucket Id'],
                forcePublish: true,
                isShadow: true,
                forceSendEvent: forceSend == "true",
                cageSum: Number(data['Amount'])
            }
        }

        const fetchReports = async (credentials, inp, paramData) => {
            const inputParams = {
                reportTypes: null,
                shadowType: null,
                vendorCodes: [inp['vendorCode']],
                beginDate: null,
                endDate: null,
                marketPlaces: null,
                includeInvalid: true,
                isOnlyShadow: true,
                metadata: {
                    "Internal_InitiatorMessageId": [inp['messageId']]
                },
                start: 0,
                limit: 50,
                cageSum: Number(paramData['Amount'])
            }
            const data = await fetchBatchReports(credentials.token, inputParams)
            if (data && data.errorMessage) {
                throw new Error(data.errorMessage)
            }
            data.reports = _.uniqBy(formatReports(data.reports, stageConfig), 'Report Id')
            data['inputParams'] = inputParams
            setTableData(data)
            setReportData(data.reports)
        }
        const performLiveAction = async (data) => {
            try {
                setShowForceButton(false);
                setErrorText("");

                const credentials = await refreshMidway();
                const inputParams = getLiveInputParams(data)
                const publishReport = await publishLiveReport(credentials.token, inputParams)
                if (publishReport && publishReport.errorMessage) throw new Error(publishReport.errorMessage)
                const {
                    response,
                    messageId,
                    sequenceId,
                    hasRecords,
                    records
                } = publishReport
                setProgressText([...progressText, {"name": "Getting Status..."}]);
                let toValidate = null
                if (hasRecords && records.length > 0) {
                    toValidate = _.sortBy(records, ['createdAt']).slice(-1)[0]
                }
                let COUNT = 0, LIMIT = 25, INCR = 5
                let failed = false, found = false
                do {
                    if (toValidate) {
                        if (!_.isEmpty(toValidate["failureReason"])) {
                            setErrorText(`Failed : ${toValidate["failureReason"]}`)
                            setProgressText([...progressText, {
                                "type": "error",
                                "name": `Failed : ${toValidate["failureReason"]}`
                            }]);
                            failed = true;
                            break;
                        } else if (toValidate["requestStatus"] && PARTIAL_SUCCESS_STATUS.includes(toValidate["requestStatus"])){
                            //uploaded to R2MS but there are more reports to process/vendor request not yet completed
                            setProgressText([...progressText, {"name": "Fetching completed reports. Request is still" +
                                    " in progress"}]);
                            await fetchReports(credentials, toValidate, data);
                        } else if (toValidate["requestStatus"] && LIVE_SUCCESS_STATUS.includes(toValidate["requestStatus"])) {
                            //vendor request processed show the report
                            setProgressText([...progressText, {"name": `${toValidate["requestStatus"]}`}]);
                            found = true;
                            break;
                        } else {
                            setProgressText([...progressText, {"name": `${toValidate["requestStatus"]}`}]);
                        }
                    }
                    const resp = await getLiveReportStatus(credentials.token, {
                        "bucketId": data['Bucket Id'],
                        "messageId": messageId ? messageId : toValidate['messageId']
                    })
                    if (resp && resp.errorMessage) throw new Error(resp.errorMessage)
                    toValidate = _.sortBy(resp.records, ['createdAt']).slice(-1)[0]
                    COUNT = COUNT + 1
                    if (COUNT <= 4) {
                        INCR = 5
                    } else if (COUNT > 4 && COUNT < 15) {//20 sec elapsed
                        INCR = 10
                    } else if (COUNT >= 15 && COUNT <= 20) {//120 sec elapsed
                        INCR = 20
                    } else {//120 sec elapsed
                        INCR = 30
                    }
                    console.log("Start next round ", COUNT, INCR)
                    await DELAY(INCR * 1000);
                } while (COUNT < LIMIT);
                if (failed) {
                    //show failed message
                    if (forceSend != "true")
                        setShowForceButton(true)
                } else if (found) {
                    //fetch and show r2ms report
                    setProgressText([...progressText, {
                        "name": `Fetching report details...`
                    }]);
                    await fetchReports(credentials, toValidate, data);
                    setProgressText([...progressText, {
                        "name": `Completed !`
                    }]);
                } else {
                    //timeout
                    setErrorText(`[TIMEOUT] Request is still in progress, Visit this page after some time [Note: TaxReports might take longer time than others].`)
                    setProgressText([...progressText, {
                        "type": "error",
                        "name": `[TIMEOUT] Request is still in progress, Visit this page after some time [Note: TaxReports might take longer time than others.].`
                    }]);
                }
            } catch (err) {
                console.error(`Error while checking for live reports : ${err.message}`)
                onOpenToast(err.message)
                setProgressText([...progressText, {"type": "error", "name": `Failed ${err.message}...`}]);
                setErrorText(`Failed ${err.message}...`)
            } finally {
                setViewLoader(false);
                setProgressText([]);
            }
        }

        performLiveAction(data);
    }, []);


    const forceRedirect = useCallback(() => {
        window.open(`/#${PAGE_LOCATIONS.LIVEREPORTS}?data=${JSON.stringify(data)}&force=true`, "_self")
        window.location.reload()
    }, [])

    return (
        !data ?
            <Alert type="informational" size="large">
                Unable to perform the required action.
            </Alert> :
            <Column heights="fit"
                    spacing="medium"
            >
                <Toaster toasts={toasts} onCloseToast={onCloseToast} alignmentHorizontal="right"
                >
                    {toast => <Alert toast={true}
                                     type="error"
                                     size="large"
                    >{toast.message}</Alert>}
                </Toaster>
                <Row width="100%" alignmentHorizontal="center">
                </Row>
                {viewLoader &&
                <Row width="100%" alignmentHorizontal="center">
                    <Loader size="large"/>
                </Row>}
                {viewLoader && <>
                    <Row width="100%" alignmentHorizontal="center">
                        <Text>Please don't close this window or tab. Your request will be completed soon.</Text>
                    </Row>
                    <Row width="100%" alignmentHorizontal="center">
                        <Text><b>TaxReports might take longer time than other reports.</b></Text>
                    </Row>
                </>}

                {
                    progressText && progressText.map((p, index) => {
                        return (
                            <Row key={_.uniqueId('key-')} width="100%" alignmentHorizontal="center">
                                <Text
                                    color={p.type == "error" ? 'error' : index < progressText.length - 1 ? 'secondary' : 'primary'}
                                    type="b400">{p.name}</Text>
                            </Row>
                        )
                    })
                }
                <Row width="100%" alignmentHorizontal="center">
                    {
                        errorText && errorText.length > 0 && <Text color={'error'} type={"b400"}>
                            {errorText}
                        </Text>
                    }
                </Row>
                <Row width="100%" alignmentHorizontal="center">
                    {showForceButton &&
                    <Button type="tertiary" size="small"
                            onClick={() => forceRedirect()}>
                        Force Try
                    </Button>
                    }
                </Row>

                {
                    reportData && reportData.length > 0 &&
                    <Row width="100%" alignmentHorizontal="center">
                        <Alert
                            type="warning"
                        >The below generated reports are not final month-end reports and may be outdated by few
                            hours.</Alert>
                    </Row>
                }
                {reportData && reportData.length > 0 &&
                <TableSection hasGlAccess={true} tableData={tableData}
                              tableDataRef={tableDataRef}
                              loaderState={setViewLoader}
                              makingCall={viewLoader}
                              onOpenToast={onOpenToast} selected={selected}
                              setSelected={setSelected} selectedRef={selectedRef}
                              currentPage={currentPage} currentPageRef={currentPageRef}
                              setCurrentPage={setCurrentPage} reportData={reportData}
                              setReportData={setReportData} reportDataRef={reportDataRef}
                              headerCheckBoxIcon={headerCheckBoxIcon}
                              setHeaderCheckBoxIcon={setHeaderCheckBoxIcon}
                              allCheckedInCurrent={allCheckedInCurrent}
                              setAllCheckedInCurrent={setAllCheckedInCurrent}
                              onLoadMoreHandler={null} loadMoreState={loadMoreState}
                              hasMore={hasMore} hasMoreRef={hasMoreRef}
                              marketPlaceRef={marketPlaceRef}
                              claimsData={claimsData} stageConfig={stageConfig}
                              disbursementData={disbursementData} disbursementDataRef={disbursementDataRef}
                />
                }

            </Column>
    )
}