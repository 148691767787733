import {CLIENT_ERROR_DEFAULT_MESSAGE, SERVER_ERROR_CODE} from "src/constants/app";
import {ServiceError} from "src/common/types";


export const extractApiError = (error: any): ServiceError => {
    const errorMessage = error?.response?.data?.message || error?.message || CLIENT_ERROR_DEFAULT_MESSAGE;
    const errorCode = error?.response?.status || SERVER_ERROR_CODE;

    return {errorMessage, errorCode};
}

export const isServerError = (errorCode: number) => {
    return errorCode >= SERVER_ERROR_CODE;
}