import {API} from "aws-amplify";
import {API_NAME} from "src/constants/app";
import {extractApiError} from "src/util/apiUtils";


export const getVendorGroupByCustomerId = async (credentials: String, inputParams: any) => {
    try {
        const path = '/getVendorGroupByExternalId';
        const body = {
            body: {
                "token": credentials,
                ...inputParams
            },
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.error(`Error while calling getVendorGroupById API : ${error}`)
        return extractApiError(error);
    }
}


export const getVendorGroupByVendorCode = async (credentials: String, inputParams: any) => {
    try {
        const path = '/getVendorGroupsByVendorCode';
        const body = {
            body: {
                "token": credentials,
                ...inputParams
            },
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.error(`Error while calling getVendorGroupByVendorCode API : ${error}`)
        return extractApiError(error);
    }
}
