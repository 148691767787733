import React, {useCallback, useEffect, useRef} from "react";
import useState from 'react-usestateref'
import Pagination from "@amzn/meridian/pagination"
import Column from "@amzn/meridian/column"
import Alert from "@amzn/meridian/alert"
import Table, {
    TableRow,
    TableCell,
    TableActionBar,
    TableActionBarOverlay,
} from "@amzn/meridian/table"
import Toggle from "@amzn/meridian/toggle"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import {format, parse} from 'date-fns'

const _ = require('lodash');
import './styles/styles.css'
import {
    REPORTS_ROW_COUNT,
} from "src/constants/app";

import useWindowDimensions from "src/components/common/Dimension";

import {getExportFileName, makeCsv} from "src/components/reports/Utils";
import Toaster from "@amzn/meridian/toaster";
import {dateColumns, extraItems, hiddenItems, renameKeys} from "src/components/monthendReconcile/ReconileConstants";


export const ReconcileTable = (props) => {

    const {
        bucketList, paginationKey, stageConfig, currentPage, setCurrentPage,
        viewLoader, setViewLoader, onOpenToast, loadMoreBuckets,
        downloadState, setDownloadState
    } = props


    const [showDetails, setShowDetails] = useState(false);


    //const pagination selection
    const {height, width} = useWindowDimensions();
    const [paginationValue, setPaginationValue, paginationValueRef] = useState(_.toInteger(height / REPORTS_ROW_COUNT) - (width > 1000 ? 0 : 1))
    const numberOfPages = Math.ceil(bucketList ? (bucketList.length / paginationValue) : 0)


    //sorting
    const [sortColumn, setSortColumn] = useState("Begin Date")
    const [sortDirection, setSortDirection] = useState("descending")
    const onSort = useCallback(({sortColumn, sortDirection}) => {
        setSortDirection(sortDirection)
        setSortColumn(sortColumn)
    }, [])
    bucketList && bucketList.sort((a, b) => {
        if (dateColumns.includes(sortColumn)) {
            if (parse(a[sortColumn], stageConfig.DATE.format, new Date()) < parse(b[sortColumn], stageConfig.DATE.format, new Date())) {
                return sortDirection === "ascending" ? -1 : 1
            }
            if (parse(a[sortColumn], stageConfig.DATE.format, new Date()) > parse(b[sortColumn], stageConfig.DATE.format, new Date())) {
                return sortDirection === "ascending" ? 1 : -1
            }
        } else {
            if (a[sortColumn] < b[sortColumn]) {
                return sortDirection === "ascending" ? -1 : 1
            }
            if (a[sortColumn] > b[sortColumn]) {
                return sortDirection === "ascending" ? 1 : -1
            }
        }
        return 0
    })


    //pagination
    const firstVisibleIndex = (currentPage - 1) * paginationValue
    const lastVisibleIndex = firstVisibleIndex + paginationValue
    const paginationHandler = useCallback((newPageNumber) => {
        setCurrentPage(newPageNumber)
    }, [])


    const handleExport = async () => {
        try {
            setDownloadState(true)
            setViewLoader(true)
            await makeCsv(bucketList, getExportFileName())
        } catch (err) {
            console.error(err)
            onOpenToast("Error while exporting table data, Try again!")
        } finally {
            setDownloadState(false)
            setViewLoader(false)
        }
        return false;
    };


    const [infoToasts, setInfoToasts] = useState([])
    const onCloseInfoToast = useCallback(
        id => setInfoToasts(infoToasts.filter(t => t.id !== id)),
        [infoToasts]
    )
    let dummyId = 0
    const onOpenInfoToast = useCallback(
        (message) => setInfoToasts(infoToasts.concat({id: `${++dummyId}`, message, timeout: 3000})),
        [infoToasts]
    )

    return (
        !bucketList ? <Alert
                type="informational"
                size="large"
            >Apply the filters to view the buckets.</Alert> :
            bucketList.length === 0 ? (
                    <Alert
                        type="warning"
                        size="large"
                    >No relevant bucket info found.</Alert>
                ) :
                <React.Fragment>
                    <Column className={"small"} width="100%" spacing="small" alignmentHorizontal="right">

                        {/*top action bar*/}
                        <Row width="100%" widths={["grid-12"]} alignmentHorizontal="left">
                            <TableActionBar widths={["30%", "70%"]}>
                                <Row width="100%" alignmentHorizontal="left">
                                            <span>
                                             Loaded <strong>{bucketList.length}</strong> buckets, Found reports for
                                                <strong> {bucketList.filter(bucket => bucket[renameKeys.reportFound] && !isNaN(bucket[renameKeys.reportFound]) && Number(bucket[renameKeys.reportFound]) > 0).length} </strong>
                                                buckets
                                            </span>
                                </Row>
                                <Row width="100%" alignmentHorizontal="right">
                                    <Button size="small" type="tertiary" disabled={downloadState}
                                            onClick={() => handleExport()}>
                                        Export Table</Button>
                                </Row>
                            </TableActionBar>
                        </Row>

                        {/*table data*/}
                        <Row width="100%" widths={["grid-12"]} alignmentHorizontal="left">
                            <div className={'small'} style={{
                                overflowX: "auto",
                                maxWidth: "100%",
                                padding: "5px",
                            }}>
                                <Table
                                    width="100%"
                                    headerRows={1}
                                    spacing="small"
                                    showDividers={true}
                                    showStripes={false}
                                    sortColumn={sortColumn}
                                    sortDirection={sortDirection}
                                    onSort={onSort}
                                    fixHeaderRows={false}
                                >
                                    {/*table headers*/}
                                    <TableRow
                                        key={'header'}
                                        highlightOnHover={true}>
                                        {_.keys(bucketList[0]).map((d) => {
                                            if (extraItems.includes(d))
                                                return
                                            if (!hiddenItems.includes(d) || (hiddenItems.includes(d) && showDetails))
                                                return <TableCell width="50px" key={d}
                                                                  sortColumn={d}>{d}</TableCell>
                                        })}
                                    </TableRow>

                                    {/*table rows*/}
                                    {bucketList.slice(firstVisibleIndex, lastVisibleIndex).map((row) => (
                                        <TableRow key={row[renameKeys.id]} highlightOnHover={true}>
                                            {_.keys(row).map((d) => {
                                                if (extraItems.includes(d))
                                                    return
                                                if (!hiddenItems.includes(d) || (hiddenItems.includes(d) && showDetails)) {
                                                    return <TableCell key={d}>
                                                        {row[d]}
                                                    </TableCell>
                                                }
                                            })}
                                        </TableRow>
                                    ))}

                                </Table>
                            </div>
                        </Row>

                        {/*footer*/}
                        <Row width="100%" widths={["30%", "70%"]}>
                            <Row width="100%" alignmentHorizontal="left">
                                <Toggle checked={showDetails} onChange={setShowDetails}>Detailed View</Toggle>
                            </Row>
                            <Row width="100%" alignmentHorizontal="right">
                                <Pagination
                                    showSkipArrows={true}
                                    numberOfPages={numberOfPages}
                                    onChange={setCurrentPage}
                                    currentPage={currentPage}
                                    onChange={paginationHandler}
                                />
                                {paginationKey ?
                                    <Button onClick={loadMoreBuckets} disabled={downloadState}
                                            type="tertiary"
                                            size="small">
                                        Load More
                                    </Button>
                                    : ''}
                            </Row>
                        </Row>
                    </Column>
                    {/*info toaster*/}
                    <Toaster toasts={infoToasts} onCloseToast={onCloseInfoToast}
                             alignmentHorizontal="center">
                        {toast => <Alert toast={true}
                                         type="informational"
                                         size="large"
                        >{toast.message}</Alert>}
                    </Toaster>

                </React.Fragment>


    );
};