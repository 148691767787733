import {Cache} from "aws-amplify";

export const AUTHENTICATION_PROVIDER = "midway-auth.amazon.com";
export const PAGE_LOCATIONS = {
    HOME: "/",
    REPORTS: "/reports",
    LIVEREPORTS: "/livereports",
    CODIGO_DUMPER: "/codigo",
    VENDOR_TOOLS: "/vendor",
    DCHS_CONSOLE: "/dchs",
    BULK_UPLOADS: "/dchsBulk",
    VENDOR_LOOKUP: "/vsearch",
    VISS_SERVICE: "/vendoridentity",
    BUCKETS_INFO: "/buckets"
};
export type DomainTypes = "beta" | "prod";

export type RegionTypes = "NA" | "EU" | "FE" | "CN";

export const WEBSITE_URLS: Record<DomainTypes, Record<RegionTypes, string>> = {
    beta: {
        NA: "https://dvs-ops.integ.digital.amazon.dev",
        EU: "https://dvs-ops-eu.integ.digital.amazon.dev",
        FE: "https://dvs-ops-fe.integ.digital.amazon.dev",
        CN: "https://dvs-ops-cn.integ.digital.amazon.dev",
    },
    prod: {
        NA: "https://dvs-ops.rta.digital.amazon.dev",
        EU: "https://dvs-ops-eu.rta.digital.amazon.dev",
        FE: "https://dvs-ops-fe.rta.digital.amazon.dev",
        CN: "https://dvs-ops-cn.rta.digital.amazon.dev",
    },
};

export const OLDER_DVS_OPS_URLS: Record<DomainTypes, Record<RegionTypes, string>> = {
    beta: {
        NA: "https://dvs-ops.integ.amazon.com/dvs-ops",
        EU: "https://dvs-ops-eu.integ.amazon.com/dvs-ops",
        FE: "https://dvs-ops-fe.integ.amazon.com/dvs-ops",
        CN: "https://dvs-ops-cn.integ.amazon.com/dvs-ops",
    },
    prod: {
        NA: "https://dvs-ops.amazon.com/dvs-ops",
        EU: "https://dvs-ops-eu.amazon.com/dvs-ops",
        FE: "https://dvs-ops-fe.amazon.com/dvs-ops",
        CN: "https://dvs-ops-cn.amazon.com/dvs-ops",
    },
};
export const DELAY = (ms: number) => new Promise((res) => setTimeout(res, ms));
export const REPORTS_PAGE_SIZE = 7;

export const REPORTS_MULTI_SELECT_LIMIT_KEY = "numSelect";
export const MAX_REPORTS_MULTI_SELECT_LIMIT = 1500;
export const MAX_REPORTS_MULTI_SELECT_LIMIT_DEFAULT = 50;
export const REPORTS_MULTI_SELECT_LIMIT = () => {
    const cacheVal = Cache.getItem(REPORTS_MULTI_SELECT_LIMIT_KEY);
    if (cacheVal && !isNaN(cacheVal) && Number(cacheVal) <= MAX_REPORTS_MULTI_SELECT_LIMIT)
        return Number(cacheVal);
    return MAX_REPORTS_MULTI_SELECT_LIMIT_DEFAULT;
}

export const R2MS_REPORTS_LIMIT_KEY = "numReports";
export const MAX_R2MS_REPORTS_LIMIT = 1500;
export const MAX_R2MS_REPORTS_LIMIT_DEFAULT = 50;
export const R2MS_REPORTS_LIMIT = () => {
    const cacheVal = Cache.getItem(R2MS_REPORTS_LIMIT_KEY);
    if (cacheVal && !isNaN(cacheVal) && Number(cacheVal) <= MAX_R2MS_REPORTS_LIMIT)
        return Number(cacheVal);
    return MAX_R2MS_REPORTS_LIMIT_DEFAULT;
}

export const ALLOWED_TO_SELECT_IN_ALL_PAGES_KEY = "canSelectInAll";
export const ALLOWED_TO_SELECT_IN_ALL_PAGES_KEY_DEFAULT = false;
export const ALLOWED_TO_SELECT_IN_ALL_PAGES = () => {
    const cacheVal = Cache.getItem(ALLOWED_TO_SELECT_IN_ALL_PAGES_KEY);
    return cacheVal && cacheVal != ALLOWED_TO_SELECT_IN_ALL_PAGES_KEY_DEFAULT
}

export const CAGE_BUCKETS_LIMIT_KEY = "numBuckets";
export const MAX_CAGE_BUCKET_LIMIT = 2000;
export const MAX_CAGE_BUCKETS_LIMIT_DEFAULT = 100;
export const CAGE_BUCKETS_LIMIT = () => {
    const cacheVal = Cache.getItem(CAGE_BUCKETS_LIMIT_KEY);
    if (cacheVal && !isNaN(cacheVal) && Number(cacheVal) <= MAX_CAGE_BUCKET_LIMIT)
        return Number(cacheVal);
    return MAX_CAGE_BUCKETS_LIMIT_DEFAULT;
}

export const CACHE_EXPIRY_DAYS = 2;

export const REPORTS_ROW_COUNT = 75;
export const DISBURSEMENT_PAGE_SIZE = 8;
export const DISBURSEMENT_SEPARATE_TABLE_PAGE_SIZE = 2;

export const BUCKET_STATUSES = ["Open", "Closed", "Sumbitted", "AggregationLocked", "AggregationStopped", "AggregationFailed", "AggregationCompleted", "Discarded"];
export const ALLOWED_BUIDS = ["KindleUnlimited", "KindleGuruRoyalty", "Sonic", "WarrantyMonthly", "KURevshare", "PRIMSConsignment", "KindleGuru", "AudibleDigital.Nodal", "KU.Nodal", "AppStore", "DxGy", "SWA", "TabletBundle", "DPP", "PrimeReadingEbooks", "FTVOperator", "NODAL", "DSV.VideoGames", "EchoBundle", "KDP", "Wisp", "AmazonMusicRoyalties", "IRLManagedEBooks", "AppStore.Nodal", "AmazonRestaurantsIN", "DSV", "Astro", "TVODRow", "eReaderBundle", "TEMPO", "KDPFixedFee", "AlexaBillingService", "AudibleRoyalties", "DOD", "StandardDPP", "StoredValueAccount", "KEP", "EPubs", "CaspianRoyaltyDemo", "AVOD", "AVS", "FuseMCB", "KDPAllStars", "KDPPrint", "AlexaSkillsStore", "AppstoreIN", "ManagedEBooks", "AIV.Cooper", "TVODRow.Nodal", "FreetimeUnlimited", "MP3", "Orca", "DVDeviceBounties", "Sancus", "AmazonMusicUnlimited", "CSG.Nodal", "Cicada", "Daric", "CU", "Falkor", "IMDbTVDevicePartner", "AIV.Banksy", "AlexaSkillsStoreRevShare", "Fuse", "Gouda", "APUB", "FTVBundle", "AlexaSkillsStoreIN", "AudibleBundles", "AIV.Banksy.Nodal", "Subs.KU", "KEPPrint", "DSV.Software", "AppStore.Banjo", "Prime.Nodal"]

export const API_NAME = "DVS_OPS_SERVICE";
export const FAQ_LINK =
    "https://w.amazon.com/bin/view/DART_Reports/";
export const TT_LINK =
    "https://issues.amazon.com/issues/create?assignedFolder=b8bd8988-5fc1-48c5-99c7-f327bff42ded";
export const PERM_MGMT =
    "https://w.amazon.com/bin/view/DCCS/DART/DVSOPS/ServiceDetails#HPermissionManagement";

export const SLACK_LINK =
    "https://amazon.enterprise.slack.com/archives/C054UMU5BKP";
export const REPORT_ID_QUERY_PARAM = "reportId"

export const CN_REGION = "cn-north-1"

export enum DATE_FORMAT_TYPE {
    DATE = "DATE",
    DATE_TIME = "DATETIME"
}

export const SERVER_ERROR_CODE = 500;
export const CLIENT_ERROR_CODE = 400;
export const CLIENT_ERROR_DEFAULT_MESSAGE = "Unexpected error on the service, try again later."