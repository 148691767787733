import Modal from "react-modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large";
import React, {useContext} from "react";
import Loader from "@amzn/meridian/loader";
import {
    renameKeys
} from "src/components/reports/ReportConstants";
import {refreshMidway} from "src/authentication/midwayTokenRetriever";
import {changeReportStatus} from "src/apis/rrg";
import checkCircleTokens from "@amzn/meridian-tokens/base/icon/check-circle"
import {ThemeContext} from "src/context/ThemeContext";
import {getCustomModalStyle} from "src/util/customModalStyle";
import {connect} from "react-redux";
import {addServiceError} from "src/reducers/app/actions";

const mapDispatch = {
    dispatchError: (value) => addServiceError(value)
};

const connector = connect(null, mapDispatch);

export const ConfirmStatusChangeComponent = (props) => {
    const {
        openStatusChangeModal, setOpenStatusChangeModal, reportData, dispatchError, setUserValidReports, stageConfig,
        statusChangeApiCalled, statusChangeApiMakingCall, statusChangeApiSuccess, statusChangeProgressText,
        setStatusChangeApiCalled, setStatusChangeApiMakingCall, setStatusChangeApiSuccess, setStatusChangeProgressText,
        isReportValid, userValidReports, userValidReportsRef
    } = props
    const themeContext = useContext(ThemeContext);

    const changeStatusViaApiCall = async (reportData) => {
        try {
            setStatusChangeApiCalled(true)
            setStatusChangeApiMakingCall(true)
            setStatusChangeProgressText("Sending Request to update status...")
            const credentials = await refreshMidway();
            const data = await changeReportStatus(credentials.token, reportData[renameKeys.reportId], !isReportValid)
            if (data && data.errorMessage) {
                dispatchError(data)
                setStatusChangeProgressText(data.errorMessage)
                return;
            }
            setUserValidReports({
                ...userValidReports, [reportData[renameKeys.reportId]]: data.reports[0]['isValid']
            })
            setStatusChangeProgressText("Report Status changed successfully.")
            setStatusChangeApiSuccess(true)
        } catch (err) {
            dispatchError(err.message)
            setStatusChangeProgressText(err.message)
        } finally {
            setStatusChangeApiMakingCall(false)
        }
    }
    return (
        reportData &&
        <Modal
            key={'modal'}
            isOpen={openStatusChangeModal}
            onRequestClose={() => setOpenStatusChangeModal(o => !o)}
            style={getCustomModalStyle(themeContext.isDarkMode, "40%", "50%")}
        >
            <Row width="100%" widths={["95%", "5%"]}>
                <Text>&nbsp;</Text>
                <Button type="icon" onClick={() => setOpenStatusChangeModal(false)}>
                    <Icon tokens={closeLargeTokens}/>
                </Button>
            </Row>
            {statusChangeApiCalled ? (
                    <>
                        {statusChangeApiMakingCall && <Row width="100%" alignmentHorizontal="center">
                            <Loader type="circular"/>
                        </Row>}
                        <Row width="100%" alignmentHorizontal="center">
                            {statusChangeApiSuccess && <Icon tokens={checkCircleTokens}/>}
                        </Row>
                        <div style={{
                            marginTop: "30px"
                        }}>
                            <Row width="100%" alignmentHorizontal="center">
                                <Text type={"h300"}>
                                    {statusChangeProgressText}
                                </Text>
                            </Row>
                        </div>
                    </>
                ) :
                (
                    <>

                        <Row width="100%">
                            <Text type={"h500"}>
                                Are you sure?
                            </Text>
                        </Row>

                        <div width="100%" style={{
                            marginTop: "20px",
                        }}>
                            <Text type={"b500"}>
                                Confirming this will change the <b><u>isValid flag</u></b> of the below report
                                to <b><u>{(!isReportValid).toString()}</u></b>.
                            </Text>
                        </div>
                        <div width="100%" style={{
                            marginTop: "15px",
                        }}>
                            <Text type={"b400"}>
                                This will make the
                                report <b><u>{!isReportValid ? 'visible' : 'invisible'}</u></b> in <b><u>Vendor
                                Central</u></b>.
                            </Text>
                        </div>
                        <div width="100%" style={{
                            marginTop: "15px",
                        }}>
                            <Text type={"b300"}>
                                <b>Vendor Code</b> : {reportData[renameKeys.vendorCode]}
                                <br/>
                                <b>Report ID</b> : {reportData[renameKeys.reportId]}
                                <br/>
                                <b>Report Type</b> : {reportData[renameKeys.reportType]}
                                <br/>
                                <b>Report
                                    Period</b> : {reportData[renameKeys.beginDate]} - {reportData[renameKeys.endDate]}
                                <br/>
                                <b>MarketPlace</b> : {reportData[renameKeys.marketPlaceName]} [ {reportData['marketplaceId']} ]
                                <br/>
                            </Text>
                        </div>
                        <div width="100%" style={{
                            marginTop: "8px",
                        }}>
                            <Text type={"b200"}>
                                Note : Only non-shadow valid reports are visible on vendor central dashboard.
                            </Text>
                        </div>

                        <Row width="100%" alignmentHorizontal={"right"}>
                            <Button type="tertiary" onClick={() => changeStatusViaApiCall(reportData)}>
                                Confirm
                            </Button>
                            <Button type="tertiary" onClick={() => setOpenStatusChangeModal(false)}>
                                Cancel
                            </Button>
                        </Row>
                    </>
                )

            }
        </Modal>
    )

}

export const ConfirmStatusChange = connector(ConfirmStatusChangeComponent);