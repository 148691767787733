import React from "react";
import {hot} from "react-hot-loader";
import * as KatalMetrics from '@amzn/katal-metrics';
import Loader from "@amzn/meridian/loader";
import Column from "@amzn/meridian/column";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import Link from "@amzn/meridian/link";

import {AUTHENTICATION_PROVIDER} from "src/constants/app";
import initialMetricsPublisher from "../metrics";
import {refreshMidway} from "src/authentication/midwayTokenRetriever";
import Amplify, {Auth} from 'aws-amplify';
import {authenticateAmplify} from "src/authentication/midwayAuthN";
import {getCurrentStageConfig, IAppStageConfig} from "src/constants/stage";
import {updateUser} from "src/reducers/user/actions";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "src/reducers";
import {AppStateKeys} from "src/reducers/app/types";
import {updateAppState} from "src/reducers/app/actions";
import {UserState} from "src/reducers/user/types";
import {
    TT_LINK
} from "src/constants/app";
import {fetchClaims} from "src/apis";
import {updateClaims} from "src/reducers/claims/actions";
import {Claims} from "src/reducers/claims/types";
import {ThemeProvider} from "src/context/ThemeContext";

enum InitializationStatus {
    Failure = 0,
    Success = 1
}

const mapState = (state: RootState) => ({
    user: state["user"],
    app: state["app"],
    claims: state["claims"]
});

const mapDispatch = {
    updateUser: (userData: any) => updateUser(userData),
    updateApp: (key: AppStateKeys, value: boolean) => updateAppState(key, value),
    updateClaims: (claimData: any) => updateClaims(claimData)
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface PropsType extends PropsFromRedux {
    children: JSX.Element;
}

export class AppInitWrapper extends React.Component<PropsType, {}> {
    private initializationMetric = new KatalMetrics.Metric.Initialization().withMonitor();
    private initializationMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForInitialization();
    private isInitialized = false;
    private stageConfig: IAppStageConfig = getCurrentStageConfig();

    async componentDidMount() {
        this.logInitializationMetric(InitializationStatus.Success);
        this.initializeAmplify(this.stageConfig);
        try {
            const response = await authenticateAmplify();
            this.props.updateUser(response.user as UserState);
            const claims = await fetchClaims(response.credentials.token as String);
            this.props.updateClaims(claims as Claims);
            this.props.updateApp("isInitializing", false);
        } catch (err) {
            console.error(`Error during initializing the application ${err}`);
            this.props.updateApp("errored", true);
        }

    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.logInitializationMetric(InitializationStatus.Failure);
        // In your application you may want to update the UI; here we will re-throw and fail the whole app, after logging
        throw error;
    }

    render() {
        if (this.props.app.errored) {
            return (
                <Column
                    height="25vh"
                    heights="fit"
                    alignmentHorizontal="center"
                    alignmentVertical="center"
                >
                    <Alert type="error" size="large">
                        <Text type="b400">
                            We have encountered an error while initializing the app. Please
                            try refreshing the page
                            <br/>
                            If the issue persists please cut a ticket to{" "}
                            <Link
                                href={TT_LINK}
                                type="secondary"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                RTA Team
                            </Link>
                        </Text>
                    </Alert>
                </Column>
            );
        }
        return this.props.app.isInitializing ? (
            <Column
                height="100vh"
                heights="fit"
                alignmentHorizontal="center"
                alignmentVertical="center"
            >
                <Loader type="circular"/>
            </Column>
        ) : (
            <ThemeProvider>
                {this.props.children}
            </ThemeProvider>
        );
    }

    // Helper method to publish the initialization metric
    private logInitializationMetric(status: InitializationStatus) {
        if (
            !this.isInitialized &&
            this.initializationMetric &&
            this.initializationMetricsPublisher
        ) {
            this.isInitialized = true;
            this.initializationMetric.setFailure(
                status !== InitializationStatus.Success
            );
            this.initializationMetricsPublisher.publish(this.initializationMetric);
        }
    }

    private initializeAmplify(stageConfig: IAppStageConfig) {
        try {
            Amplify.configure({
                Auth: {
                    identityPoolId: stageConfig["AMPLIFY"]["COGNITO"]["IDENTITY_POOL_ID"],
                    region: stageConfig["AWS_REGION"],
                    mandatorySignIn: true,
                    refreshHandlers: {[AUTHENTICATION_PROVIDER]: refreshMidway}
                },
                API: {
                    endpoints: stageConfig["SERVICE_ENDPOINTS"],
                    custom_header: async () => {
                        return {Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`};
                    }
                }
            });
            const currentConfig = Auth.configure();
        } catch (err) {
            console.error("Error while init. amplify : ", err)
        }
    }
}

// Hot reload any react changes when running the dev server
export default connector(hot(module)(AppInitWrapper));