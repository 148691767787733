import React, {useCallback, useEffect} from "react";
import useState from 'react-usestateref'
import Alert from "@amzn/meridian/alert"
import Modal from "react-modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large";
import Textarea from "@amzn/meridian/textarea";
import Loader from "@amzn/meridian/loader";
import {submitFeedback} from "src/apis";
import {refreshMidway} from "src/authentication/midwayTokenRetriever";
import {PERM_MGMT} from "src/constants/app";
import Link from "@amzn/meridian/link";
import {getCustomModalStyle} from "src/util/customModalStyle";

export const Feedback = (props) => {
    if (process.env.NODE_ENV !== 'test')
        Modal.setAppElement('#root');
    const {openFeedback, setOpenFeedback, isDarkMode} = props
    const [value, setValue, valueRef] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)

    const submitFeed = async () => {
        try {
            setIsSubmitting(true)
            setError(false)
            setErrorMessage("")
            if (_.isEmpty(valueRef.current) || valueRef.current.length < 10 || valueRef.current.length > 800)
                throw new Error("The content must have 10 - 800 characters.")
            const credentials = await refreshMidway();
            const response = await submitFeedback(credentials.token, valueRef.current)
            if (response.errorMessage)
                throw  new Error(response.errorMessage)
            setFeedbackSubmitted(true)
        } catch (err) {
            setError(true);
            setErrorMessage(err.message)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <Modal
            isOpen={openFeedback}
            onRequestClose={() => setOpenFeedback(o => !o)}
            style={getCustomModalStyle(isDarkMode, "40%", "60%")}
        >
            <Row width="100%" widths={["95%", "5%"]}>
                <Text>&nbsp;</Text>
                <Button type="icon" onClick={() => setOpenFeedback(false)}>
                    <Icon tokens={closeLargeTokens}/>
                </Button>
            </Row>

            <Text type="h400">Provide RTA Team Feedback</Text>
            <br/>
            {!feedbackSubmitted && isSubmitting && <Loader type="linear" size="small"/>}
            {!feedbackSubmitted && error && <Alert type="error">{errorMessage}</Alert>}
            {feedbackSubmitted &&
            <Alert type="success">Thank you for your valuable feedback.</Alert>}
            <br/>
            {!feedbackSubmitted && <Textarea disabled={isSubmitting} value={value} onChange={setValue}
                                             placeholder="Do let us know your feedback on what you liked and how we can improve. We are listening."/>}
            <br/>
            <Text type="b300">For permission issues/access management check <Link href={PERM_MGMT} target="_blank"
                                                                                  rel="noopener noreferrer"
                                                                                  type="secondary">this
                wiki.</Link></Text>
            <br/><br/>
            <Row alignmentHorizontal="right" widths="fit">
                <Button
                    type="secondary"
                    size="small"
                    onClick={() => setOpenFeedback(false)}
                >
                    Close
                </Button>
                {!feedbackSubmitted &&
                <Button disabled={isSubmitting} type="primary" size="small" onClick={() => submitFeed()}>
                    Submit
                </Button>}
            </Row>
        </Modal>
    )
}