
import ReactDOM from "react-dom";
import App from "./components/App";
import AppInitWrapper from "./components/AppInitWrapper";
import { Provider } from "react-redux";
import "./index.scss";
import React from "react";
import store from "src/configure_store";

document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.render(
        <Provider store={store()}>
            <AppInitWrapper>
                <App />
            </AppInitWrapper>
        </Provider>,
        document.getElementById("root")
    );
});