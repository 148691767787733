import { ServiceError, ServiceErrorToast } from "src/common/types";
import { UPDATE_APP_STATE, AppStateKeys, AppState } from "./types";
import {CLIENT_ERROR_CODE} from "src/constants/app";

export function updateAppState(
    stateVariable: AppStateKeys,
    value: any
): UPDATE_APP_STATE {
    return {
        type: "@@APP/UPDATE_APP_STATE",
        payload: { [stateVariable]: value },
    };
}

export const ADD_API_ERROR = "@@APP/ADD_APP_STATE";

export function addServiceError(
    value: ServiceError | string
): UPDATE_APP_STATE {
    return {
        type: ADD_API_ERROR,
        payload: typeof value === 'string'
            ? { errorMessage: value, errorCode: CLIENT_ERROR_CODE}
            : value,
    };
}

export const REMOVE_API_ERROR = "@@APP/REMOVE_APP_STATE";

export function removeServiceError(
    value: ServiceErrorToast
): UPDATE_APP_STATE {
    return {
        type: REMOVE_API_ERROR,
        payload: value,
    };
}