import {DomainTypes, API_NAME, WEBSITE_URLS} from "src/constants/app";

export interface IAmplifyConfigs {
    COGNITO: {
        IDENTITY_POOL_ID: string;
    };
}

type AWSRegions =
    | "us-east-1"
    | "us-west-2"
    | "ap-southeast-1"
    | "eu-west-1"
    | "cn-north-1";


type ServiceEndpoint = {
    name: string;
    endpoint: string;
    region: AWSRegions;
};

type DateFormat = {
    locale: string;
    format: string;
};

export interface IAppStageConfig {
    AMPLIFY: IAmplifyConfigs;
    AWS_REGION: AWSRegions;
    REALM: "NA" | "EU" | "FE" | "CN";
    STAGE: DomainTypes;
    SERVICE_ENDPOINTS: Array<ServiceEndpoint>;
    DATE: DateFormat;
}

export const devConfig: IAppStageConfig = {
    AMPLIFY: {
        COGNITO: {
            IDENTITY_POOL_ID: "us-east-1:51cc0844-e6c7-4acb-bd5e-b5247ffb6e47",
        },
    },
    AWS_REGION: "us-east-1",
    REALM: "NA",
    STAGE: "beta",
    SERVICE_ENDPOINTS: [
        {
            name: API_NAME,
            endpoint: "https://s0pzsvunhe.execute-api.us-east-1.amazonaws.com/beta",
            region: "us-east-1",
        },
    ],
    DATE: {
        locale: "en-US",
        format: "yyyy-MM-dd"
    }
};


export const stageConfig: Record<string, IAppStageConfig> = {
    "https://kmarxlh.people.amazon.dev:4321": devConfig,
    [WEBSITE_URLS.beta.NA]: {
        AMPLIFY: {
            COGNITO: {
                IDENTITY_POOL_ID: "us-east-1:51cc0844-e6c7-4acb-bd5e-b5247ffb6e47",
            },
        },
        AWS_REGION: "us-east-1",
        REALM: "NA",
        STAGE: "beta",
        SERVICE_ENDPOINTS: [
            {
                name: API_NAME,
                endpoint: "https://s0pzsvunhe.execute-api.us-east-1.amazonaws.com/beta",
                region: "us-east-1",
            },
        ],
        DATE: {
            locale: "en-US",
            format: "yyyy-MM-dd"
        }
    },
    [WEBSITE_URLS.beta.FE]: {
        AMPLIFY: {
            COGNITO: {
                IDENTITY_POOL_ID: "us-west-2:b4537e71-2f10-406f-bddf-98c183812962",
            },
        },
        AWS_REGION: "us-west-2",
        REALM: "FE",
        STAGE: "beta",
        SERVICE_ENDPOINTS: [
            {
                name: API_NAME,
                endpoint: "https://djzrzrilc0.execute-api.us-west-2.amazonaws.com/beta",
                region: "us-west-2",
            },
        ],
        DATE: {
            locale: "en-US",
            format: "yyyy-MM-dd"
        }
    },
    [WEBSITE_URLS.beta.EU]: {
        AMPLIFY: {
            COGNITO: {
                IDENTITY_POOL_ID: "eu-west-1:53c9242a-3f93-4c87-92ce-5abf7f096cbd",
            },
        },
        AWS_REGION: "eu-west-1",
        REALM: "EU",
        STAGE: "beta",
        SERVICE_ENDPOINTS: [
            {
                name: API_NAME,
                endpoint: "https://kryu96qd28.execute-api.eu-west-1.amazonaws.com/beta",
                region: "eu-west-1",
            },
        ],
        DATE: {
            locale: "en-GB",
            format: "yyyy-MM-dd"
        }
    },
    [WEBSITE_URLS.beta.CN]: {
        AMPLIFY: {
            COGNITO: {
                IDENTITY_POOL_ID: "us-east-1:51cc0844-e6c7-4acb-bd5e-b5247ffb6e47",
            },
        },
        AWS_REGION: "us-east-1",
        REALM: "CN",
        STAGE: "beta",
        SERVICE_ENDPOINTS: [
            {
                name: API_NAME,
                endpoint: "https://9l3j7grky1.execute-api.us-east-1.amazonaws.com/beta",
                region: "us-east-1",
            },
        ],
        DATE: {
            locale: "en-US",
            format: "yyyy-MM-dd"
        }
    },
    [WEBSITE_URLS.prod.NA]: {
        AMPLIFY: {
            COGNITO: {
                IDENTITY_POOL_ID: "us-east-1:c825104a-e14c-4c73-ac76-93ca65727ddf",
            },
        },
        AWS_REGION: "us-east-1",
        REALM: "NA",
        STAGE: "prod",
        SERVICE_ENDPOINTS: [
            {
                name: API_NAME,
                endpoint: "https://ld4avccmtg.execute-api.us-east-1.amazonaws.com/prod",
                region: "us-east-1",
            },
        ],
        DATE: {
            locale: "en-US",
            format: "yyyy-MM-dd"
        }
    },
    [WEBSITE_URLS.prod.FE]: {
        AMPLIFY: {
            COGNITO: {
                IDENTITY_POOL_ID: "us-west-2:da181427-61ef-428a-bda7-194754c65d0b",
            },
        },
        AWS_REGION: "us-west-2",
        REALM: "FE",
        STAGE: "prod",
        SERVICE_ENDPOINTS: [
            {
                name: API_NAME,
                endpoint: "https://t03dq8iha7.execute-api.us-west-2.amazonaws.com/prod",
                region: "us-west-2",
            },
        ],
        DATE: {
            locale: "en-US",
            format: "yyyy-MM-dd"
        }
    },
    [WEBSITE_URLS.prod.EU]: {
        AMPLIFY: {
            COGNITO: {
                IDENTITY_POOL_ID: "eu-west-1:b1be5990-32b6-4677-8b76-cd7ff6f94f26",
            },
        },
        AWS_REGION: "eu-west-1",
        REALM: "EU",
        STAGE: "prod",
        SERVICE_ENDPOINTS: [
            {
                name: API_NAME,
                endpoint: "https://mssel06l35.execute-api.eu-west-1.amazonaws.com/prod",
                region: "eu-west-1",
            },
        ],
        DATE: {
            locale: "en-GB",
            format: "yyyy-MM-dd"
        }
    },
    [WEBSITE_URLS.prod.CN]: {
        AMPLIFY: {
            COGNITO: {
                IDENTITY_POOL_ID: "cn-north-1:ec4a4cb0-ac18-4cb8-8dab-a04fa8e75b5a",
            },
        },
        AWS_REGION: "cn-north-1",
        REALM: "CN",
        STAGE: "prod",
        SERVICE_ENDPOINTS: [
            {
                name: API_NAME,
                endpoint: "https://jvrj5t445g.execute-api.cn-north-1.amazonaws.com.cn/prod",
                region: "cn-north-1",
            },
        ],
        DATE: {
            locale: "en-US",
            format: "yyyy-MM-dd"
        }
    }
};

export const getCurrentStageConfig = (domain?: string): IAppStageConfig => {
    let config: IAppStageConfig;
    if (domain) {
        config = stageConfig[domain];
    } else {
        config = stageConfig[window.location.origin];
    }
    if (!config) {
        throw `Error Initialising Stage Config : ${domain}`;
    }
    return config;
};