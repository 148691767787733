import React from "react";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import HomePageCard, {LinkType} from "src/components/homePage/HomePageCard";
import {OLDER_DVS_OPS_URLS, PAGE_LOCATIONS} from "src/constants/app";
import {useHistory} from "react-router-dom";
import {getCurrentStageConfig, IAppStageConfig} from "src/constants/stage";

const DashboardCards = () => {
    let history = useHistory();
    const stageConfig: IAppStageConfig = getCurrentStageConfig();
    const baseUrl = OLDER_DVS_OPS_URLS[stageConfig.STAGE][stageConfig.REALM];
    return (
        <Column heights="fill" alignmentHorizontal="left">
            <Row
                spacingInset="none"
                alignmentVertical="stretch"
                wrap="down"
                width="100%"
                widths={["grid-4", "grid-4", "grid-4", "grid-4", "grid-4", "grid-4"]}
            >
                <HomePageCard heading="Vendor Central Reports" isNew={true} linkType={LinkType.Internal}
                              link={PAGE_LOCATIONS.REPORTS}>
                    <Text type="b300">
                        View and manage Vendor Central reports
                        {" "}
                    </Text>
                </HomePageCard>

                <HomePageCard heading="Codigo Dumper" linkType={LinkType.External}
                              link={`${baseUrl}${PAGE_LOCATIONS.CODIGO_DUMPER}`}>
                    <Text type="b300">
                        Invoke methods of Codigo-based BSF services
                        {" "}
                    </Text>
                </HomePageCard>

                <HomePageCard heading="Vendor Management Tools" linkType={LinkType.External}
                              link={`${baseUrl}${PAGE_LOCATIONS.VENDOR_TOOLS}`}>
                    <Text type="b300">
                        Create and edit vendor settings
                        {" "}
                    </Text>
                </HomePageCard>

                <HomePageCard heading="Digital Costing History Console" linkType={LinkType.External}
                              link={`${baseUrl}${PAGE_LOCATIONS.DCHS_CONSOLE}`}>
                    <Text type="b300">
                        View and update ASIN or SKU attributes in DCHS
                        {" "}
                    </Text>
                </HomePageCard>

                <HomePageCard heading="DCHS Bulk Upload/Download Console" linkType={LinkType.External}
                              link={`${baseUrl}${PAGE_LOCATIONS.BULK_UPLOADS}`}>
                    <Text type="b300">
                        Upload and download dchs overrides
                        {" "}

                    </Text>
                </HomePageCard>

                <HomePageCard heading="Vendor Lookup" linkType={LinkType.External}
                              link={`${baseUrl}${PAGE_LOCATIONS.VENDOR_LOOKUP}`}>
                    <Text type="b300">
                        View vendor settings from various systems
                        {" "}
                    </Text>
                </HomePageCard>

            </Row>
        </Column>
    );
};

export default DashboardCards;