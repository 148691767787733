import React from "react";
import Heading from "@amzn/meridian/heading";
import {useHistory} from "react-router-dom";
import Tag from "@amzn/meridian/tag";
import {css} from "emotion";
import Box from "@amzn/meridian/box";
import Link from "@amzn/meridian/link";

export enum LinkType {
    Internal = "internal",
    External = "external"
}

interface ICardProps {
    heading?: string;
    isNew?: boolean;
    backgroundColor?: string;
    link?: string;
    linkType?: LinkType;
}

const style = () =>
    css({
        boxShadow: "rgba(11, 12, 12, 0.16) 0px 2px 4px 0px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "rgb(231, 233, 233)",
        borderImage: "initial",
        borderRadius: "4px"
    });

const HomePageCard: React.FunctionComponent<ICardProps> = props => {
    let linkParams: any = {};
    let history = useHistory();
    if (props.linkType === LinkType.Internal) {
        linkParams.onClick = history.push;
    } else {
        linkParams.target = "_blank";
    }
    return (
        <Link
            {...linkParams}
            href={props.link}
            type="secondary"
        >
            <Box className={style()} spacingInset="large">
                <Heading level={3} type="h200">
                    {props.heading}{" "}
                    {props.isNew && <Tag type="success">New</Tag>}{" "}
                </Heading>
                <br/>
                {props.children}
            </Box>
        </Link>
    );
};

export default HomePageCard;