import React, {useCallback} from "react";
import initialMetricsPublisher from "../../metrics";
import Row from "@amzn/meridian/row";
import {RRGLiveReport} from "src/components/liveReport/RRGLiveReport";


export class LiveReports extends React.Component {

    componentDidMount() {
        const actionMetricsPublisher =
            initialMetricsPublisher.newChildActionPublisherForMethod("PageLoad");
        actionMetricsPublisher.publishCounterMonitor('live-report-page-load', 1);
    }

    render() {
        return (
            <Row width="100%" widths={["grid-12"]}>
                <RRGLiveReport/>
            </Row>
        );
    }
}

export default LiveReports;