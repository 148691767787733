import React, {useEffect} from "react";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import token from "@amzn/meridian-tokens/base/color";
import Heading from "@amzn/meridian/heading";
import {css} from "emotion";
import {useSelector} from "react-redux";
import {RootState} from "src/reducers";
import DashboardCards from "src/components/homePage/DashboardCards";
import Content from "src/components/common/Content";
import initialMetricsPublisher from "src/metrics";

const Home: React.FunctionComponent = () => {

    useEffect(() => {
        const actionMetricsPublisher =
            initialMetricsPublisher.newChildActionPublisherForMethod("PageLoad");
        actionMetricsPublisher.publishCounterMonitor('home-page-load', 1);
    }, []);

    const userNameSelector = (state: RootState) => state.user;
    const userData = useSelector(userNameSelector);

    const style = () =>
        css({
            borderBottom: `1px solid ${token.colorGrayTranslucentMid500}`,
        });
    return (
        <>
            <Row
                spacingInset="medium"
                wrap="down"
                widths={["grid-12"]}
                backgroundColor="secondary"
                className={style()}
            >
                <Content spacingInset="medium">
                    <Heading level={1} type="d100">
                        Welcome {(userData && userData.username) || ''}
                    </Heading>
                    <Text type="b300">
                        This portal hosts the tools and services that can be used by vendor managers and internal users
                        to search and download reports. The existing DVS-OPS services can be accessed from the older
                        portal.
                    </Text>{" "}
                </Content>
            </Row>
            <Row
                spacingInset="medium"
                wrap="down"
                widths={["grid-12"]}
            >
                <DashboardCards/>
            </Row>
        </>
    );
};

export default Home;