import Alert from "@amzn/meridian/alert";
import Toaster from "@amzn/meridian/toaster";
import Link from "@amzn/meridian/link";
import React from "react";
import {ConnectedProps, connect} from "react-redux";
import {ServiceErrorToast} from "src/common/types";
import {RootState} from "src/reducers";
import {removeServiceError } from "src/reducers/app/actions";
import {SLACK_LINK} from "src/constants/app";
import {isServerError} from "src/util/apiUtils";

const mapState = (state: RootState) => ({
    app: state["app"]
});

const mapDispatch = {
    onCloseToast: (value: ServiceErrorToast) => removeServiceError(value)
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;


const ErrorToastComponent =  ({ app, onCloseToast }: PropsFromRedux) => {
    return (
        <>
            <Toaster toasts={app.apiErrors} onCloseToast={onCloseToast}>
                {toast => (
                    <Alert toast={true} type="error">
                        {
                            isServerError(toast.errorCode)
                                ? (<span>There was an error in our service, try again later. Contact us in slack at <Link href={SLACK_LINK} target="_blank">#caspian-payments-help.</Link></span>)
                                : toast.errorMessage
                        }
                    </Alert>
                )}
            </Toaster>
        </>
    )
};

export const ErrorToast = connector(ErrorToastComponent)
