import {API} from "aws-amplify";
import {API_NAME} from "src/constants/app";
import {extractApiError} from "src/util/apiUtils";

/**
 * ping apis just to make sure backand lambda is responding
 */
export const getEcho = async (inputString: any) => {
    try {
        const path = '/echo';
        const body = {
            body: {"string": inputString || "Test Call to validate apis."},
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.error(`Error while calling Echo API : ${error}`)
        return extractApiError(error);
    }
}

/**
 * submits the feedback [creates a sim on behalf of the submitter]
 * @param credentials
 * @param feedback
 */
export const submitFeedback = async (credentials: String, feedback: string) => {
    try {
        const path = '/submitfeedback';
        const body = {
            body: {
                "token": credentials,
                feedback
            }
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.log(`Error while calling submit feedback API : ${error}`)
        return extractApiError(error);
    }
}

/**
 * returns the marketplace , report types and shadow types the user can access
 * @param credentials
 */
export const fetchClaims = async (credentials: String) => {
    try {
        const path = '/getclaims';
        const body = {
            body: {
                "token": credentials
            }
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.log(`Error while calling fetchClaims API : ${error}`)
        return extractApiError(error);
    }
}

/**
 * returns the paginated reports based on given inputs
 * @param credentials
 * @param reportInputData
 */
export const fetchReports = async (credentials: String, reportInputData: any) => {
    try {
        const path = '/getreports';
        const body = {
            body: {
                "token": credentials,
                ...reportInputData
            }
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.log(`Error while calling fetchReports API : ${error}`)
        return extractApiError(error);
    }
}

/**
 * returns the paginated reports based on given inputs
 * @param credentials
 * @param reportInputData
 */
export const fetchBatchReports = async (credentials: String, reportInputData: any) => {
    try {
        const path = '/getbatchreports';
        const body = {
            body: {
                "token": credentials,
                ...reportInputData
            }
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.log(`Error while calling fetchBatchReports API : ${error}`)
        return extractApiError(error);
    }
}

/**
 * returns the report data
 * @param credentials
 * @param reportId
 */
export const fetchReportById = async (credentials: String, inputParam: any) => {
    try {
        const path = '/getReportById';
        const body = {
            body: {
                "token": credentials,
                ...inputParam
            }
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.log(`Error while calling fetchReportById API : ${error}`)
        return extractApiError(error);
    }
}

/**
 * return blob data representing the report user requested for download
 * @param credentials
 * @param downloadInputData
 */
export const downloadReport = async (credentials: String, downloadInputData: any) => {
    try {
        const path = '/downloadreport';
        const body = {
            body: {
                "token": credentials,
                ...downloadInputData
            }
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.log(`Error while calling downloadReport API : ${error}`)
        return extractApiError(error);
    }
}

/**
 * return blob data representing the batch reports user requested for download
 * @param credentials
 * @param downloadInputData
 */
export const downloadBatchReports = async (credentials: String, downloadInputData: any) => {
    try {
        const path = '/batchdownloadreport';
        const body = {
            body: {
                "token": credentials,
                ...downloadInputData
            }
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.log(`Error while calling batch downloadReport API : ${error}`)
        return extractApiError(error);
    }
}

/**
 * returns the accumulated disbursement data
 * @param credentials
 * @param disbursementInput Data
 */
export const getAccumulatingDisbursement = async (credentials: String, disbursementInputData: any) => {
    try {
        const path = '/getdisbursements';
        const body = {
            body: {
                "token": credentials,
                ...disbursementInputData
            }
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.log(`Error while calling accumulating disbursement API : ${error}`)
        return extractApiError(error);
    }
}


/**
 * returns the bucket data
 * @param credentials
 * @param bucketInput Data
 */
export const getBucketsInfo = async (credentials: String, bucketInputData: any) => {
    try {
        const path = '/getBuckets';
        const body = {
            body: {
                "token": credentials,
                ...bucketInputData
            }
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.log(`Error while calling getBucketsInfo API : ${error}`)
        return extractApiError(error);
    }
}
