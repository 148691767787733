import React from "react";
import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import { css } from "emotion";

const styles = css({
    margin: "0 auto"
});

interface IContentProps {
    spacingInset?: any;
    backgroundColor?: any;
    maxWidth?: any;
    className?: any;
}
class Content extends React.Component<IContentProps> {
    render() {
        const {
            children,
            spacingInset,
            backgroundColor,
            maxWidth = 976,
            className,
            ...extraProps
        } = this.props;
        return (
            <Box
                backgroundColor={backgroundColor}
                spacingInset={spacingInset}
                className={className}
            >
                <Column className={styles} width="100%">
                    {this.props.children}
                </Column>
            </Box>
        );
    }
}

export default Content;