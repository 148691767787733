import Modal from "react-modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large";
import {DOWNLOAD_TYPES, REPORT_MODAL_TYPES} from "src/components/reports/ReportConstants";
import downloadLargeTokens from "@amzn/meridian-tokens/base/icon/download-large";
import {CsvToHtmlTable} from "react-csv-to-table";
import {DisbursementTable} from "src/components/liveReport/DisbursementTable";
import React, {useCallback, useContext, useRef, useState} from "react";
import Alert from "@amzn/meridian/alert";
import Menu, {MenuItem} from "@amzn/meridian/menu";
import Loader from "@amzn/meridian/loader";
import {
    renameKeys
} from "src/components/reports/ReportConstants";
import {getCustomModalStyle} from "src/util/customModalStyle";
import {ThemeContext} from "src/context/ThemeContext";


export const ModalViewComponent = (props) => {
    const {
        openModal,
        setOpenModal,
        modalData,
        loaderState, makingCall,
        downloadState,
        modalReport,
        saveFile,
        handleDownload
    } = props
    const themeContext = useContext(ThemeContext);
    const downloadMenuRef = useRef()
    const [openMenu, setOpenMenu] = useState(false)
    const openMenuButton = useCallback(() => setOpenMenu(true), [])
    const onMenuClose = useCallback(() => setOpenMenu(false), [])
    return (
        <Modal
            key={'modal'}
            isOpen={openModal}
            onRequestClose={() => setOpenModal(o => !o)}
            style={getCustomModalStyle(themeContext.isDarkMode, "80%", "80%")}
        >
            {makingCall === true && <Row width="100%" alignmentHorizontal="center">
                <Loader type="linear"/>
            </Row>}
            <Row width="100%" widths={["95%", "5%"]}>
                <Text>&nbsp;</Text>
                <Button type="icon" onClick={() => setOpenModal(false)}>
                    <Icon tokens={closeLargeTokens}/>
                </Button>
            </Row>
            {modalData && (modalData.type == REPORT_MODAL_TYPES.VIEW_REPORT ?
                    <div>
                        {modalData.strippedRows === true &&
                        <>
                            <Alert type="warning" size="medium">Due to large number of rows the data has been stripped.
                                Download the
                                report to view the entire content.</Alert>
                            <br/>
                        </>
                        }
                        <Row width="100%" widths={["80%", "20%"]}>
                            <Text type="b500">
                                Vendor Central DVS Report : {modalReport && modalReport[renameKeys.reportId]}
                            </Text>
                            <Row width="100%" alignmentHorizontal="right">
                                <Button type="icon" disabled={downloadState}
                                        size="small"
                                        type="tertiary" ref={downloadMenuRef}
                                        onClick={openMenuButton}>
                                    Download <Icon tokens={downloadLargeTokens}/>
                                </Button>
                                <Menu
                                    anchorNode={downloadMenuRef.current}
                                    open={openMenu}
                                    position="bottom"
                                    onClose={onMenuClose}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            // handleDownload(modalData.report, DOWNLOAD_TYPES.TEXT, false);
                                            saveFile(modalData.report, modalData.data, modalData.downloadType);
                                            setOpenMenu(false);
                                        }}>Text</MenuItem>
                                    {modalReport && modalReport[renameKeys.formatTypes].includes("csv") &&
                                    <MenuItem
                                        onClick={() => {
                                            handleDownload(modalData.report, DOWNLOAD_TYPES.CSV, false);
                                            setOpenMenu(false);
                                        }}>csv</MenuItem>}
                                    {modalReport && modalReport[renameKeys.formatTypes].includes("excel") &&
                                    <MenuItem
                                        onClick={() => {
                                            handleDownload(modalData.report, DOWNLOAD_TYPES.EXCEL, false);
                                            setOpenMenu(false);
                                        }}>xls</MenuItem>}
                                    {modalReport && modalReport[renameKeys.formatTypes].includes("excel") &&
                                    <MenuItem
                                        onClick={() => {
                                            handleDownload(modalData.report, DOWNLOAD_TYPES.EXCEL, true);
                                            setOpenMenu(false);
                                        }}>xlsx</MenuItem>}
                                </Menu>
                            </Row>
                        </Row>
                        <br/>
                        {modalData.rows.map(m => {
                            return <CsvToHtmlTable
                                key={'data'}
                                data={m}
                                // csvDelimiter=","
                                tableClassName="table table-striped table-hover"
                            />
                        })}
                    </div>
                    :
                    <div>
                        <DisbursementTable data={modalData.data} loaderState={loaderState}>
                        </DisbursementTable>
                    </div>
            )}
        </Modal>
    )
}