import {API} from "aws-amplify";
import {API_NAME} from "src/constants/app";
import {extractApiError} from "src/util/apiUtils";

/**
 * publish live report event or get existing one
 * @param
 *  {
        "token": "",
        "startDate": 1622962800000,
        "endDate": 1623308400000,
        "period": "",
        "marketPlaceId": 1,
        "purpose": "RoyaltyPaymentShadow",
        "businessUnitId": "",
        "vendorCode": "",
        "currency": "",
        "marketplaceCurrency": "",
        "preferredCurrency": "",
        "glCode": 1,
        "bucketId": "",
        "forcePublish": true,
        "isShadow":true
    }
 */
export const publishLiveReport = async (credentials: String, publishLiveReportBody: any) => {
    try {
        const path = '/sendPublishReportEvent';
        const body = {
            body: {
                "token": credentials,
                ...publishLiveReportBody
            },
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.error(`Error while calling publishLiveReport API : ${error}`)
        return extractApiError(error);
    }
}


/**
 * get status of previously enqueued request
 * @param
 *  {
        "token": "",
        "bucketId": "",
        "messageId": true
    }
 */
export const getLiveReportStatus = async (credentials: String, liveReportStatusBody: any) => {
    try {
        const path = '/checkLiveReportStatus';
        const body = {
            body: {
                "token": credentials,
                ...liveReportStatusBody
            },
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.error(`Error while calling checkLiveReportStatus API : ${error}`)
        return extractApiError(error);
    }
}


export const changeReportStatus = async (credentials: String, reportId: String, newStatus: Boolean) => {
    try {
        const path = '/updateReportStatus';
        const body = {
            body: {
                "token": credentials,
                reportId: reportId,
                isValid: newStatus
            },
        };
        return await API.post(API_NAME, path, body);
    } catch (error) {
        console.error(`Error while calling changeReportStatus API : ${error}`)
        return extractApiError(error);
    }
}
