import Row from "@amzn/meridian/row";
import React, {useCallback, useEffect, useRef} from "react";
import useState from 'react-usestateref'
import Loader from "@amzn/meridian/loader";
import {refreshMidway} from "src/authentication/midwayTokenRetriever";
import {useSelector} from "react-redux";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import {getCurrentStageConfig} from "src/constants/stage";
import Column from "@amzn/meridian/column";
import './styles/styles.css'
import {InputBar} from "src/components/monthendReconcile/InputBar";
import {BUCKET_STATUSES, CAGE_BUCKETS_LIMIT} from "src/constants/app";
import {fetchReports, getBucketsInfo} from "src/apis";
import {formatBuckets} from "src/components/monthendReconcile/ReconileConstants";
import {ReconcileTable} from "src/components/monthendReconcile/Table";

const _ = require('lodash');


export const ReconcileDashboard = (props) => {

    const claimsSelector = (state) => state.claims;
    const stageConfig = getCurrentStageConfig();
    const claimsData = useSelector(claimsSelector);

    const [marketPlace, setMarketPlace, marketPlaceRef] = useState([])
    const [productGL, setProductGL, productGLRef] = useState("")
    const [bucketStatus, setBucketStatus, bucketStatusRef] = useState([])
    const [buid, setBuid, buidRef] = useState([])
    const [loadShadow, setLoadShadow, loadShadowRef] = useState("No")
    const [startDate, setStartDate, startDateRef] = useState('')
    const [bucketData, setBucketData, bucketDataRef] = useState(null)
    const [paginationKey, setPaginationKey, paginationKeyRef] = useState('')
    const [currentPage, setCurrentPage, currentPageRef] = useState(1)
    const [downloadState, setDownloadState, downloadStateRef] = useState(false)
    const [inputData, setInputData, inputDataRef] = useState(null)

    //alert message toasts
    let toastUniqueIds = 0
    const [toasts, setToasts] = useState([])
    const onCloseToast = useCallback(
        id => setToasts(toasts.filter(t => t.id !== id)),
        [toasts]
    )
    const onOpenToast = useCallback(
        (message) => setToasts(toasts.concat({id: `${++toastUniqueIds}`, message, timeout: 3000})),
        [toasts]
    )

    const [viewLoader, setViewLoader] = useState(false);

    const checkForReport = async (token, bucketId) => {
        try {
            const params = {
                "metadata": {
                    "DisbursementBucketIds": [bucketId]
                }
            }
            const data = await fetchReports(token, params)
            if (data && data.errorMessage) {
                return -1
            }
            return data.reports.length
        } catch (err) {
            console.error(err)
            onOpenToast("Error while checking for report, Try again!")
            return -1
        } finally {
        }
    }

    const callApi = async () => {
        setDownloadState(true)
        setViewLoader(true)
        const credentials = await refreshMidway();
        if (!credentials)
            throw new Error("Invalid Credentials.")
        const inputParams = _.isEmpty(inputDataRef.current) ? {
            marketPlaceIds: marketPlaceRef.current,
            glProductGroups: [productGLRef.current],
            startDate: _.isEmpty(startDateRef.current) ? null : Math.floor(new Date(startDateRef.current).valueOf() / 1000),
            filterShadowBuckets: loadShadowRef.current === "Yes",
            endDate: _.isEmpty(startDateRef.current) ? Math.floor(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 5).valueOf() / 1000)
                : Math.floor(new Date(new Date(startDateRef.current).getFullYear(), new Date(startDateRef.current).getMonth() + 1, 5).valueOf() / 1000),
            status: bucketStatusRef.current,
            businessUnitIds: buidRef.current,
            pageSize: CAGE_BUCKETS_LIMIT(),
            fetchReports: true
        } : inputDataRef.current
        inputParams['paginationKey'] = _.isEmpty(paginationKeyRef.current) ? null : paginationKeyRef.current
        let bucketsInfo = bucketDataRef.current || [], currentCall = 1;
        const CALL_LIMIT = 1;
        while (currentCall <= CALL_LIMIT) {
            console.log("Call Count : ", currentCall, inputParams["paginationKey"])
            const data = await getBucketsInfo(credentials.token, inputParams)
            if (data && data.errorMessage) {
                onOpenToast(data.errorMessage)
                break;
            }
            data["bucketList"].map(bucket => {
                bucket["reportFound"] = _.isEmpty(bucket['reportIds']) ? 0 : bucket['reportIds'].length
            })
            bucketsInfo.push(...data["bucketList"])
            inputParams["paginationKey"] = data["paginationKey"]
            setPaginationKey(data["paginationKey"])
            if (_.isEmpty(data) || _.isEmpty(data["paginationKey"]) || _.isEmpty(data["bucketList"])) {
                break;
            }
            currentCall = currentCall + 1;
        }
        console.log("Returned Buckets : ", currentCall, bucketsInfo.length)
        setBucketData(formatBuckets(bucketsInfo, stageConfig))
        setInputData(inputParams)
        setViewLoader(false)
    }
    const loadBuckets = useCallback(async () => {
        try {
            if (_.isEmpty(marketPlaceRef.current))
                onOpenToast("Please choose marketplace.")
            else if (_.isEmpty(bucketStatusRef.current))
                onOpenToast("Please choose bucketStatus.")
            else if (_.isEmpty(buidRef.current))
                onOpenToast("Please enter businessUnitId.")
            else if (_.isEmpty(productGLRef.current))
                onOpenToast("Please choose product gl.")
            else {
                setBucketData(null)
                setInputData(null)
                await callApi()
            }
        } catch (err) {
            console.error(err)
            onOpenToast("Error while fetching data, Try again!")
        } finally {
            setViewLoader(false)
            setDownloadState(false)
        }
    }, [])


    const loadMoreBuckets = useCallback(async () => {
        try {
            await callApi()
        } catch (err) {
            console.error(err)
            onOpenToast("Error while fetching buckets, Try again!")
        } finally {
            setViewLoader(false)
            setDownloadState(false)
        }
    }, [])

    useEffect(() => {
        setBucketStatus([BUCKET_STATUSES[6]])
    }, [])

    return (
        !claimsData.isInternal ?
            <Alert type="informational" size="large">
                You are not authorized to perform this action.
            </Alert> :
            <Column heights="fit"
                    spacing="small"
                    className={"small"}
            >
                <Toaster toasts={toasts} onCloseToast={onCloseToast} alignmentHorizontal="right"
                >
                    {toast => <Alert toast={true}
                                     type="error"
                                     size="large"
                    >{toast.message}</Alert>}
                </Toaster>
                <Row width="100%" alignmentHorizontal="center">
                </Row>

                <div style={{
                    padding: "5px",
                }}>
                    <Row width="100%" alignmentHorizontal="left">
                        <InputBar claimsData={claimsData}
                                  marketPlace={marketPlace} setMarketPlace={setMarketPlace}
                                  marketPlaceRef={marketPlaceRef}
                                  productGL={productGL} setProductGL={setProductGL} productGLRef={productGLRef}
                                  bucketStatus={bucketStatus} setBucketStatus={setBucketStatus}
                                  bucketStatusRef={bucketStatusRef}
                                  buid={buid} setBuid={setBuid} buidRef={buidRef}
                                  loadShadow={loadShadow} setLoadShadow={setLoadShadow}
                                  startDate={startDate} setStartDate={setStartDate} loadBuckets={loadBuckets}
                                  downloadState={downloadState}/>

                    </Row>
                </div>

                {viewLoader &&
                <Row width="100%" alignmentHorizontal="center" style={{
                    zIndex: "100"
                }}>
                    <Loader size="large"/>
                </Row>}

                <ReconcileTable bucketList={bucketData} paginationKey={paginationKey} stageConfig={stageConfig}
                                currentPage={currentPage} setCurrentPage={setCurrentPage} viewLoader={viewLoader}
                                setViewLoader={setViewLoader} onOpenToast={onOpenToast}
                                loadMoreBuckets={loadMoreBuckets} downloadState={downloadState}
                                setDownloadState={setDownloadState}/>

            </Column>
    )
}