import contrib from "lodash-contrib";
import {format} from "date-fns";

const _ = require('lodash')

export const renameKeys = {
    businessUnitId: "BUID",
    reportIds: "Report Id",
    id: "Id",
    vendorCode: "Vendor",
    created: "Created",
    startDate: "Start Date",
    endDate: "End Date",
    lastModified: "Last Modified",
    childBucketIds: "Child Bucket Ids",
    sharedBucketId: "Shared Bucket Ids",
    gl: "GL",
    key: "Key",
    marketplaceId: "MarketPlace Id",
    marketplaceName: "MarketPlace",
    metadata: "Metadata",
    status: "Status",
    type: "Type",
    reportFound: "Reports"
}
export const hiddenItems = [renameKeys.reportIds, renameKeys.type, renameKeys.metadata]
export const extraItems = []
export const toOmit = [renameKeys.childBucketIds, renameKeys.sharedBucketId, renameKeys.marketplaceName, renameKeys.key]
export const dateColumns = [renameKeys.created, renameKeys.endDate, renameKeys.lastModified, renameKeys.startDate]

export const formatBuckets = (bucketInfos, stageConfig) => {
    if (!bucketInfos) return []
    return bucketInfos.map(r => {
        r = contrib.renameKeys(r, renameKeys)
        r[renameKeys.metadata] = JSON.stringify(r[renameKeys.metadata])
        _.values(renameKeys).forEach(v => {
            if (!_.has(r, v)) r[v] = ""
        })
        dateColumns.map(dc => {
            const timestamp = r[dc]
            const converted = format(new Date(_.toInteger(timestamp) * 1000), stageConfig.DATE.format)
            r[dc] = converted === "01/01/1970" ? timestamp : converted
            return true;
        })
        toOmit.map(t => delete r[t])
        return r
    })

}