import React from "react";
import {SideBar} from "src/components/reports/SideBar";
import initialMetricsPublisher from "../../metrics";
import {useSelector} from "react-redux";
import Row from "@amzn/meridian/row";

export class Reports extends React.Component {
    componentDidMount() {
        const actionMetricsPublisher =
            initialMetricsPublisher.newChildActionPublisherForMethod("PageLoad");
        actionMetricsPublisher.publishCounterMonitor('report-page-load', 1);

    }

    render() {
        return (
            <Row width="100%" widths={["grid-12"]} alignmentHorizontal="left">
                <SideBar> </SideBar>
            </Row>
        );
    }
}

export default Reports;