import Modal from "react-modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large";
import React from "react";
import {getCustomModalStyle} from "src/util/customModalStyle";


export const GeneralConfirmDialog = (props) => {
    const {
        openModal, setOpenModal, isDarkMode
    } = props

    return (
        <Modal
            key={'modal'}
            isOpen={openModal}
            onRequestClose={() => setOpenModal(o => !o)}
            style={getCustomModalStyle(isDarkMode, "30%", "50%")}
        >
            <Row width="100%" widths={["95%", "5%"]}>
                <Text>&nbsp;</Text>
                <Button type="icon" onClick={() => setOpenModal(false)}>
                    <Icon tokens={closeLargeTokens}/>
                </Button>
            </Row>

            <Row width="100%">
                <Text type={"b400"}>
                    Your requested data will be downloaded soon. If it does not start automatically please refresh the
                    page and try again.
                </Text>
            </Row>

            <div width="100%" style={{
                marginTop: "50px",
            }}>
                <Row width="100%" alignmentHorizontal={"right"}>
                    <Button type="tertiary" onClick={() => setOpenModal(false)}>
                        Okay
                    </Button>
                </Row>
            </div>
        </Modal>
    )

}