import React from "react";
import './styles/styles.css'
import ReactJson from 'react-json-view'
import Alert from "@amzn/meridian/alert";
import Row from "@amzn/meridian/row";


export const DataViewer = (props) => {
    const {jsonData} = props;
    return (
        <div style={{padding: "20px"}}>
            {jsonData ?
                <ReactJson src={jsonData} /> :
                <Row width="100%" alignmentHorizontal="center">
                </Row>}
        </div>
    )
}