import React, {useCallback, useContext, useEffect} from "react";
import useState from 'react-usestateref'
import Pagination from "@amzn/meridian/pagination"
import Column from "@amzn/meridian/column"
import Alert from "@amzn/meridian/alert"
import Table, {
    TableRow,
    TableCell, TableActionBar,
} from "@amzn/meridian/table"
import Row from "@amzn/meridian/row"
import {getCurrentStageConfig} from "src/constants/stage";
import {format, parse} from 'date-fns'

const _ = require('lodash');
const contrib = require('lodash-contrib');
import './styles/styles.css'
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Modal from "react-modal";
import Text from "@amzn/meridian/text";
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large";
import {CsvToHtmlTable} from "react-csv-to-table";
import './styles/styles.css'
import {DISBURSEMENT_PAGE_SIZE, DISBURSEMENT_SEPARATE_TABLE_PAGE_SIZE, PAGE_LOCATIONS} from "src/constants/app";
import documentTokens from "@amzn/meridian-tokens/base/icon/document"
import {ALLOWED_LIVE_BUSINESS} from "src/components/reports/ReportConstants";
import {getCustomModalStyle} from "src/util/customModalStyle";
import {ThemeContext} from "src/context/ThemeContext";
import {addServiceError} from "src/reducers/app/actions";
import {connect} from "react-redux";

const {Parser} = require('json2csv');

const hiddenItems = ["bucketLineData", "Bucket Id", "glProductGroup", "Bucket Status"]
const restrictedItems = ["bucketLineData"]
const renameKeys = {
    "amountString": "Amount",
    "currencyCode": "Currency",
    "bucketId": "Bucket Id",
    "marketplaceName": "MarketPlace",
    "status": "Bucket Status",
    "startDate": "Begin Date",
    "endDate": "End Date",
    "businessUnitId": "Business Id",
    "vendorCode": "Vendor Code"
}
const toOmit = ["amount"]
const dateColumns = ["Begin Date", "End Date"]
export const amountColumns = ["Amount"]

const mapDispatch = {
    dispatchError: (value) => addServiceError(value)
};

const connector = connect(null, mapDispatch);

export const DisbursementTableComponent = (props) => {
        if (process.env.NODE_ENV !== 'test')
            Modal.setAppElement('#root');
        const stageConfig = getCurrentStageConfig();
        const themeContext = useContext(ThemeContext);
        const {
            loaderState, dispatchError, isSeparateTable, claimsData, canTriggerLive
        } = props
        let {bucketDataList, totalAmount, inputParams, errorMessage} = props.data || {}
        if (bucketDataList) {
            bucketDataList = bucketDataList.map(b => {
                b = _.omit(b, toOmit)
                b = contrib.renameKeys(b, renameKeys)
                _.values(renameKeys).forEach(v => {
                    if (!_.has(b, v)) b[v] = ""
                })
                b['Begin Date'] = format(new Date(_.toInteger(b['Begin Date']) * 1000), stageConfig.DATE.format)
                b['End Date'] = format(new Date(_.toInteger(b['End Date']) * 1000), stageConfig.DATE.format)
                b.bucketLineData = JSON.stringify(b.bucketLineData)
                return {
                    [renameKeys.businessUnitId]: b[renameKeys.businessUnitId],
                    [renameKeys.vendorCode]: b[renameKeys.vendorCode],
                    [renameKeys.marketplaceName]: b[renameKeys.marketplaceName],
                    [renameKeys.startDate]: b[renameKeys.startDate],
                    [renameKeys.endDate]: b[renameKeys.endDate],
                    [renameKeys.amountString]: b[renameKeys.amountString],
                    [renameKeys.currencyCode]: b[renameKeys.currencyCode],
                    ...b,
                    'View': 'View'
                }
            })
        }

        //const pagination selection
        const [paginationValue, setPaginationValue] = useState(isSeparateTable === true ? DISBURSEMENT_SEPARATE_TABLE_PAGE_SIZE : DISBURSEMENT_PAGE_SIZE)
        const numberOfPages = Math.ceil(bucketDataList ? (bucketDataList.length / paginationValue) : 0)

        //pagination
        const [currentPage, setCurrentPage] = useState(1)
        const firstVisibleIndex = (currentPage - 1) * paginationValue
        const lastVisibleIndex = firstVisibleIndex + paginationValue
        const paginationHandler = useCallback((newPageNumber) => {
            setCurrentPage(newPageNumber)
        }, [])

        //sorting
        const [sortColumn, setSortColumn] = useState("Begin Date")
        const [sortDirection, setSortDirection] = useState("descending")
        const onSort = useCallback(({sortColumn, sortDirection}) => {
            setSortDirection(sortDirection)
            setSortColumn(sortColumn)
        }, [])
        bucketDataList && bucketDataList.sort((a, b) => {
            if (dateColumns.includes(sortColumn)) {
                if (parse(a[sortColumn], stageConfig.DATE.format, new Date()) < parse(b[sortColumn], stageConfig.DATE.format, new Date())) {
                    return sortDirection === "ascending" ? -1 : 1
                }
                if (parse(a[sortColumn], stageConfig.DATE.format, new Date()) > parse(b[sortColumn], stageConfig.DATE.format, new Date())) {
                    return sortDirection === "ascending" ? 1 : -1
                }
            } else if (amountColumns.includes(sortColumn)) {
                if (_.toNumber(a[sortColumn]) < _.toNumber(b[sortColumn])) {
                    return sortDirection === "ascending" ? -1 : 1
                }
                if (_.toNumber(a[sortColumn]) > _.toNumber(b[sortColumn])) {
                    return sortDirection === "ascending" ? 1 : -1
                }
            } else {
                if (a[sortColumn] < b[sortColumn]) {
                    return sortDirection === "ascending" ? -1 : 1
                }
                if (a[sortColumn] > b[sortColumn]) {
                    return sortDirection === "ascending" ? 1 : -1
                }
            }
            return 0
        })

        const shouldAllowLiveReport = (businessId) => {
            return canTriggerLive && ALLOWED_LIVE_BUSINESS.some(buid =>
                businessId != null && businessId.toLowerCase().includes(buid.toLowerCase())
            )
        }
        const [showDetails, setShowDetails] = useState(false);
        const [openModal, setOpenModal, openModalRef] = useState(false);
        const [modalData, setModalData, modalDataRef] = useState(null);
        const viewBucketLines = async (bucketLineData) => {
            loaderState(true)
            setModalData(null)
            if (bucketLineData) {
                let lineItems = JSON.parse(bucketLineData)
                lineItems = lineItems.map(l => {
                    return _.omit(l, "bucketLineAmountString")
                })
                const csv = new Parser({quote: ''}).parse(lineItems);
                setModalData(csv)
                setOpenModal(o => !o)
            } else {
                dispatchError("Empty Bucket Lines.")
            }
            loaderState(false)
        }

        const stringAmount = totalAmount ? Object.keys(totalAmount).map(k => {
            return `${totalAmount[k].toFixed(2)} ${k}`
        }) : null

        const onClickedLiveReports = useCallback((data) => {
            data = _.omit(data, "bucketLineData")
            window.open(`/#${PAGE_LOCATIONS.LIVEREPORTS}?data=${JSON.stringify(data)}`, "_blank")
        }, [])

        return (
            isSeparateTable === true && _.isEmpty(bucketDataList) ? ''
                : !bucketDataList ? (
                    <Alert
                        type="informational"
                        size="large"
                    >Apply the filters to view the details.</Alert>
                ) : bucketDataList.length == 0 ?
                    (
                        <Alert
                            type="informational"
                            size="large"
                        >No details found for the given inputs.</Alert>
                    )
                    : (
                        <React.Fragment>
                            <Modal
                                isOpen={openModal}
                                onRequestClose={() => setOpenModal(o => !o)}
                                style={getCustomModalStyle(themeContext.isDarkMode, "80%", "80%")}
                            >
                                <Row width="100%" widths={["95%", "5%"]}>
                                    <Text>&nbsp;</Text>
                                    <Button type="icon" onClick={() => setOpenModal(false)}>
                                        <Icon tokens={closeLargeTokens}/>
                                    </Button>
                                </Row>

                                <Text
                                    type="b500"
                                >Bucket Lines</Text>
                                <CsvToHtmlTable
                                    data={modalData}
                                    csvDelimiter=","
                                    tableClassName="table table-striped table-hover"
                                />
                            </Modal>

                            <Column className={"small"} width="100%" spacing="small" alignmentHorizontal="right">
                                <div style={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    padding: "5px",
                                    border: "1px solid black"
                                }}>
                                    <Row width="100%" widths={["grid-12"]} alignmentHorizontal="left">
                                        <div style={{
                                            overflowX: "auto",
                                            maxWidth: "100%",
                                        }}>
                                            <TableActionBar widths={["30%", "70%"]}>
                                                <Row width="100%" alignmentHorizontal="left">
                                                    <span><strong>Accrued Royalties</strong></span>
                                                </Row>
                                            </TableActionBar>
                                            <Table
                                                headerRows={1}
                                                showDividers={true}
                                                showStripes={false}
                                                sortColumn={sortColumn}
                                                sortDirection={sortDirection}
                                                onSort={onSort}
                                                spacing="small"
                                                fixHeaderRows={false}
                                            >
                                                {/*table headers*/}
                                                <TableRow
                                                    key={'header'}
                                                    highlightOnHover={true}>
                                                    {_.keys(bucketDataList[0]).map((d) => {
                                                        if (!restrictedItems.includes(d) && (!hiddenItems.includes(d) || (hiddenItems.includes(d) && showDetails))) {
                                                            if (d === "View" && shouldAllowLiveReport(bucketDataList[0]['Business Id']))
                                                                return <TableCell key={d} sortColumn={d}>{d}</TableCell>
                                                            else if (d !== "View")
                                                                return <TableCell key={d} sortColumn={d}>{d}</TableCell>
                                                        }
                                                    })}
                                                </TableRow>

                                                {/*table rows*/}
                                                {bucketDataList.slice(firstVisibleIndex, lastVisibleIndex).map((row) => (
                                                    <TableRow key={row['Bucket Id']} highlightOnHover={true}>
                                                        {_.keys(row).map((d) => {
                                                            if (d === "View") {
                                                                if (shouldAllowLiveReport(row['Business Id'])) {
                                                                    return (
                                                                        <TableCell key={d}>
                                                                            <Button type="icon" size="small"
                                                                                    onClick={() => onClickedLiveReports(row)}>
                                                                                <Icon tokens={documentTokens}>View</Icon>
                                                                            </Button>
                                                                        </TableCell>
                                                                    )
                                                                }
                                                            } else if (!restrictedItems.includes(d) && (!hiddenItems.includes(d) || (hiddenItems.includes(d) && showDetails))) {
                                                                return <TableCell key={d}>{row[d]}</TableCell>
                                                            }
                                                        })}
                                                    </TableRow>
                                                ))}

                                            </Table>
                                        </div>
                                    </Row>

                                    <Row width="100%" widths={["70%", "30%"]} spacingInset="300">
                                        <Row width="100%" alignmentHorizontal="left">
                                            <Text type="b200">The above list includes the accrued royalty amount under Product
                                                GL
                                                : {bucketDataList[0]['glProductGroup']} for {bucketDataList[0][renameKeys.vendorCode]} in
                                                current month.
                                            </Text>
                                        </Row>
                                        {
                                            bucketDataList.length > 5 && <Row width="100%" alignmentHorizontal="right">
                                                <Pagination
                                                    showSkipArrows={true}
                                                    numberOfPages={numberOfPages}
                                                    onChange={setCurrentPage}
                                                    currentPage={currentPage}
                                                    onChange={paginationHandler}
                                                />
                                            </Row>
                                        }
                                    </Row>
                                </div>
                            </Column>
                        </React.Fragment>
                    )


        );
    }
;

export const DisbursementTable = connector(DisbursementTableComponent);