import React, {useCallback, useContext, useEffect} from "react";
import useState from 'react-usestateref'
import Alert from "@amzn/meridian/alert"
import Modal from "react-modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import closeLargeTokens from "@amzn/meridian-tokens/base/icon/close-large";
import {
    ALLOWED_TO_SELECT_IN_ALL_PAGES,
    ALLOWED_TO_SELECT_IN_ALL_PAGES_KEY,
    ALLOWED_TO_SELECT_IN_ALL_PAGES_KEY_DEFAULT,
    CACHE_EXPIRY_DAYS,
    CAGE_BUCKETS_LIMIT, CAGE_BUCKETS_LIMIT_KEY, MAX_CAGE_BUCKET_LIMIT, MAX_CAGE_BUCKETS_LIMIT_DEFAULT,
    MAX_R2MS_REPORTS_LIMIT,
    MAX_R2MS_REPORTS_LIMIT_DEFAULT,
    MAX_REPORTS_MULTI_SELECT_LIMIT,
    MAX_REPORTS_MULTI_SELECT_LIMIT_DEFAULT,
    PERM_MGMT,
    R2MS_REPORTS_LIMIT,
    R2MS_REPORTS_LIMIT_KEY,
    REPORTS_MULTI_SELECT_LIMIT,
    REPORTS_MULTI_SELECT_LIMIT_KEY
} from "src/constants/app";
import Input from "@amzn/meridian/input";
import {Cache} from "aws-amplify";
import Toggle from "@amzn/meridian/toggle";
import {ThemeContext} from "src/context/ThemeContext";
import {getCustomModalStyle} from "src/util/customModalStyle";

const _ = require('lodash');

export const SettingsModal = (props) => {
    if (process.env.NODE_ENV !== 'test')
        Modal.setAppElement('#root');

    const {openSettings, setOpenSettings} = props
    const [errorMessage, setErrorMessage] = useState("")
    const [settingsSaved, setSettingsSaved] = useState("")
    const [numReports, setNumReports] = useState(0)
    const [numSelect, setNumSelect] = useState(0)
    const [numBuckets, setNumBuckets] = useState(0)
    const [canSelectInAll, setCanSelectInAll] = useState(false)
    const themeContext = useContext(ThemeContext);


    useEffect(() => {
        setNumReports(R2MS_REPORTS_LIMIT())
        setNumSelect(REPORTS_MULTI_SELECT_LIMIT())
        setNumBuckets(CAGE_BUCKETS_LIMIT())
        setCanSelectInAll(ALLOWED_TO_SELECT_IN_ALL_PAGES())
    }, []);

    const saveDesiredSettings = () => {
        try {
            setErrorMessage("")
            setSettingsSaved("")
            const date = new Date();
            const expiration = date.setDate(date.getDate() + CACHE_EXPIRY_DAYS);
            if (numReports > 0 && numReports < MAX_R2MS_REPORTS_LIMIT) {
                Cache.setItem(R2MS_REPORTS_LIMIT_KEY, numReports, {expires: expiration})
            } else {
                return setErrorMessage(`Number of reports to fetch in one call must be less than ${MAX_R2MS_REPORTS_LIMIT}`)
            }
            if (numSelect > 0 && numSelect < MAX_REPORTS_MULTI_SELECT_LIMIT) {
                Cache.setItem(REPORTS_MULTI_SELECT_LIMIT_KEY, numSelect, {expires: expiration})
            } else {
                return setErrorMessage(`Number of reports that can be downloaded in batch must be less than ${MAX_REPORTS_MULTI_SELECT_LIMIT}`)
            }
            if (numBuckets > 0 && numBuckets < MAX_CAGE_BUCKET_LIMIT) {
                Cache.setItem(CAGE_BUCKETS_LIMIT_KEY, numBuckets, {expires: expiration})
            } else {
                return setErrorMessage(`Number of buckets that can be fetched in single call must be less than ${MAX_CAGE_BUCKET_LIMIT}`)
            }
            Cache.setItem(ALLOWED_TO_SELECT_IN_ALL_PAGES_KEY, canSelectInAll, {expires: expiration})
            setSettingsSaved("Successfully saved the settings.")
        } catch (err) {
            setErrorMessage(err.message)
        } finally {

        }
    }
    const resetSettings = () => {
        try {
            setErrorMessage("")
            setSettingsSaved("")
            setNumReports(MAX_R2MS_REPORTS_LIMIT_DEFAULT)
            setNumSelect(MAX_REPORTS_MULTI_SELECT_LIMIT_DEFAULT)
            setNumBuckets(MAX_CAGE_BUCKETS_LIMIT_DEFAULT)
            setCanSelectInAll(ALLOWED_TO_SELECT_IN_ALL_PAGES_KEY_DEFAULT)
            Cache.removeItem(R2MS_REPORTS_LIMIT_KEY);
            Cache.removeItem(REPORTS_MULTI_SELECT_LIMIT_KEY);
            Cache.removeItem(CAGE_BUCKETS_LIMIT_KEY);
            Cache.removeItem(ALLOWED_TO_SELECT_IN_ALL_PAGES_KEY);
            setSettingsSaved("Successfully reset the settings to default values.")
        } catch (err) {
            setErrorMessage(err.message)
        } finally {

        }
    }

    return (
        <Modal
            key={'modal'}
            isOpen={openSettings}
            onRequestClose={() => setOpenSettings(o => !o)}
            style={getCustomModalStyle(themeContext.isDarkMode, "40%", "50%")}
        >
            <Row width="100%" widths={["95%", "5%"]}>
                <Text>&nbsp;</Text>
                <Button type="icon" onClick={() => setOpenSettings(false)}>
                    <Icon tokens={closeLargeTokens}/>
                </Button>
            </Row>

            <Row width="100%">
                <Text type={"h400"}>
                    Available Settings
                </Text>
            </Row>

            {!_.isEmpty(errorMessage) && <Alert type="error">{errorMessage}</Alert>}
            {!_.isEmpty(settingsSaved) && <Alert type="success">{settingsSaved}</Alert>}

            <div width="50%" style={{
                marginTop: "20px",
            }}>
                <Row width="100%" widths={["70%", "30%"]}>
                    <Text type={"b500"}>
                        Number of reports to fetch in single call
                    </Text>
                    <Input
                        id="numReports"
                        value={numReports}
                        onChange={setNumReports}
                        type="number"
                        suffix=""
                        max
                    />
                </Row>
            </div>

            <div width="100%" style={{
                marginTop: "20px",
            }}>
                <Row width="100%" widths={["70%", "30%"]}>
                    <Text type={"b500"}>
                        Number of reports that can be downloaded in batch
                    </Text>
                    <Input
                        id="numSelect"
                        value={numSelect}
                        onChange={setNumSelect}
                        type="number"
                        suffix=""
                    />
                </Row>
            </div>

            <div width="100%" style={{
                marginTop: "20px",
            }}>
                <Row width="100%" widths={["70%", "30%"]}>
                    <Text type={"b500"}>
                        Number of buckets to fetch in single call
                    </Text>
                    <Input
                        id="numBuckets"
                        value={numBuckets}
                        onChange={setNumBuckets}
                        type="number"
                        suffix=""
                    />
                </Row>
            </div>

            <div width="100%" style={{
                marginTop: "20px",
            }}>
                <Row width="100%" widths={["70%", "30%"]}>
                    <Text type={"b500"}>
                        Select reports in all pages
                    </Text>
                    <Toggle checked={canSelectInAll} onChange={setCanSelectInAll}/>
                </Row>
            </div>


            <div width="100%" style={{
                marginTop: "20px",
            }}>
                <Row width="100%" alignmentHorizontal={"right"}>
                    <Button type="tertiary" onClick={() => saveDesiredSettings()}>
                        Save
                    </Button>
                    <Button type="tertiary" onClick={() => resetSettings()}>
                        Reset
                    </Button>
                </Row>
            </div>


        </Modal>
    )
}