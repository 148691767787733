import Modal from "react-modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, {useCallback, useEffect, useRef} from "react";
import useState from 'react-usestateref'
import Loader from "@amzn/meridian/loader";
import {refreshMidway} from "src/authentication/midwayTokenRetriever";
import {useSelector} from "react-redux";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import {getCurrentStageConfig} from "src/constants/stage";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import Input from "@amzn/meridian/input";
import './styles/styles.css'
import {getVendorGroupByCustomerId, getVendorGroupByVendorCode} from "src/apis/viss";
import {DataViewer} from "src/components/vendorIdentity/DataViewer";

const _ = require('lodash');


export const VISSDashboard = (props) => {

    const claimsSelector = (state) => state.claims;
    const claimsData = useSelector(claimsSelector);

    //alert message toasts
    let toastUniqueIds = 0
    const [toasts, setToasts] = useState([])
    const onCloseToast = useCallback(
        id => setToasts(toasts.filter(t => t.id !== id)),
        [toasts]
    )
    const onOpenToast = useCallback(
        (message) => setToasts(toasts.concat({id: `${++toastUniqueIds}`, message, timeout: 3000})),
        [toasts]
    )

    const [viewLoader, setViewLoader] = useState(false);
    const [vendorCode, setVendorCode, vendorCodeRef] = useState('')
    const [customerId, setCustomerId, customerIdRef] = useState('')
    const [jsonData, setJsonData, jsonDataRef] = useState(null)

    const searchByVendorCode = useCallback(async () => {
        try {
            setViewLoader(true)
            setJsonData(null)
            if (_.isEmpty(vendorCodeRef.current))
                onOpenToast("Please enter vendor code.")
            else {
                const credentials = await refreshMidway();
                if (!credentials)
                    throw new Error("Invalid Credentials.")

                const inputParams = {
                    vendorCode: vendorCodeRef.current
                }
                const data = await getVendorGroupByVendorCode(credentials.token, inputParams)
                if (data && data.errorMessage) {
                    return onOpenToast(data.errorMessage)
                }
                setJsonData(data['relations'])
            }
        } catch (err) {
            console.error(err)
            onOpenToast("Error while fetching data, Try again!")
        } finally {
            setViewLoader(false)
        }
    }, [])


    const searchByCustomerId = useCallback(async () => {
        try {
            setViewLoader(true)
            setJsonData(null)
            if (_.isEmpty(customerIdRef.current))
                onOpenToast("Please enter customer id.")
            else {
                const credentials = await refreshMidway();
                if (!credentials)
                    throw new Error("Invalid Credentials.")

                const inputParams = {
                    customerId: customerIdRef.current
                }
                const data = await getVendorGroupByCustomerId(credentials.token, inputParams)
                if (data && data.errorMessage) {
                    return onOpenToast(data.errorMessage)
                }
                setJsonData(data['vendorGroup'])
            }
        } catch (err) {
            console.error(err)
            onOpenToast("Error while fetching data, Try again!")
        } finally {
            setViewLoader(false)
        }
    }, [])


    return (
        !claimsData.isInternal ?
            <Alert type="informational" size="large">
                You are not authorized to perform this action.
            </Alert> :
            <Column heights="fit"
                    spacing="small"
                    className={"small"}
            >
                <Toaster toasts={toasts} onCloseToast={onCloseToast} alignmentHorizontal="right"
                >
                    {toast => <Alert toast={true}
                                     type="error"
                                     size="large"
                    >{toast.message}</Alert>}
                </Toaster>
                <Row width="100%" alignmentHorizontal="center">
                </Row>

                <div style={{
                    padding: "5px",
                }}>
                    <Row width="100%" alignmentHorizontal="left">
                        <Input
                            size="medium"
                            value={vendorCode}
                            onChange={setVendorCode}
                            type="text"
                            placeholder="Vendor Code"
                        />
                        <Button onClick={searchByVendorCode} type="primary" size="medium" disabled={viewLoader}>Get
                            Groups By Vendor</Button>
                        <Text>Or</Text>
                        <Input
                            size="medium"
                            value={customerId}
                            onChange={setCustomerId}
                            type="text"
                            placeholder="Customer Id"
                        />
                        <Button onClick={searchByCustomerId} type="primary" size="medium" disabled={viewLoader}>Get
                            Groups By Customer</Button>
                    </Row>
                </div>

                {viewLoader &&
                <Row width="100%" alignmentHorizontal="center" style={{
                    zIndex: "100"
                }}>
                    <Loader size="large"/>
                </Row>}

                <DataViewer jsonData={jsonData}/>
            </Column>
    )
}