import React, { useState, useCallback } from "react";
import AppLayout from "@amzn/meridian/app-layout";
import Header from "src/components/layouts/Header";
import Home from "src/components/homePage/Home";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import { PAGE_LOCATIONS } from "src/constants/app";
import Reports from "src/components/reports/Reports";
import LiveReports from "src/components/liveReport/LiveReport";
import VendorIdentityRoot from "src/components/vendorIdentity/VendorIdentityRoot";
import ReconcileRoot from "src/components/monthendReconcile/ReconcileRoot";

export class App extends React.Component {
    render() {
        return (
            <Router>
                <AppLayout headerComponent={Header} backgroundColor="alternatePrimary">
                    <Header />
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path={PAGE_LOCATIONS.REPORTS}>
                            <Reports/>
                        </Route>
                        <Route path={PAGE_LOCATIONS.LIVEREPORTS}>
                            <LiveReports/>
                        </Route>
                        <Route path={PAGE_LOCATIONS.VISS_SERVICE}>
                            <VendorIdentityRoot/>
                        </Route>
                        <Route path={PAGE_LOCATIONS.BUCKETS_INFO}>
                            <ReconcileRoot/>
                        </Route>
                    </Switch>
                </AppLayout>
            </Router>
        );
    }
}

export default App;
