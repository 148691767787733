import {Claims, ClaimsActionTypes, UPDATE_CLAIMS} from "./types";

const initialState: Claims = {
    markets: [],
    marketIds: {},
    reportTypes: [],
    shadowTypes: [],
    glReportMap: {},
    isInternal: false,
    hasUpdatePermission: false,
    errorMessage: null
};

export function ClaimsReducer(
    state = initialState,
    action: ClaimsActionTypes
): Claims {
    switch (action.type) {
        case UPDATE_CLAIMS:
            const {payload} = action
            let reportTypes: any = []
            payload.glReportMap && Object.keys(payload.glReportMap).forEach((k: string) => {
                const description = payload.glReportMap[k]
                reportTypes.push({
                    id: k, name: Object.keys(description)[0], reportTypes: Object.values(description)[0]
                })
            })
            if (!payload.isInternal) {
                const allowedGls = payload.reportTypes ? Object.keys(payload.reportTypes) : []
                reportTypes = reportTypes.filter((r: any) => allowedGls.includes(r.id))
            }
            return {
                ...state,
                ...action.payload,
                reportTypes
            };
        default:
            return state;
    }
}